import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';
import { ZIndexes } from '../../../util/ZIndexes';

export const useColumnMenus = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('md'));
};

export const BANNER_HEIGHT = 56;

export const SidebarAndMap = ({
  sidebar,
  banner,
  map,
}: {
  sidebar: ReactNode;
  banner?: ReactNode;
  map: ReactNode;
}) => {
  const columnView = useColumnMenus();
  return (
    <Grid container direction={'column'}>
      <Grid item container direction={columnView ? 'column-reverse' : 'row'}>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          xl={3}
          height="calc(100vh - 48px)"
          maxWidth="100% !important"
          overflow="auto"
        >
          {sidebar}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={8}
          xl={9}
          bgcolor="background.paper"
          style={{ overflow: 'none' }}
        >
          {banner && (
            <div style={{ zIndex: ZIndexes.MAP_BANNER, width: '100%', height: BANNER_HEIGHT }}>
              {banner}
            </div>
          )}
          <div
            style={{ width: '100%', height: `calc(100% - ${BANNER_HEIGHT * (banner ? 1 : 0)}px)` }}
          >
            {map}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
