import { GridRowData } from '@material-ui/data-grid';
import axios, { AxiosRequestConfig } from 'axios';
import { array, string } from 'fp-ts';
import { option } from 'fp-ts/es6';
import { sequenceT } from 'fp-ts/es6/Apply';
import { toNullable } from 'fp-ts/es6/Option';
import { ordString } from 'fp-ts/es6/Ord';
import { pipe } from 'fp-ts/es6/pipeable';
import { Feature } from 'ol';
import AnimatedCluster from 'ol-ext/layer/AnimatedCluster';
import { GeoJSONPolygon } from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';
import Polygon from 'ol/geom/Polygon';
import { Vector as VectorLayer } from 'ol/layer';
import 'ol/ol.css';
import { Cluster } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import { StyleFunction } from 'ol/style/Style';
import React from 'react';
import {
  microfenceClusterFromJson,
  layerFromJson,
  LayerResult,
  multipolygonsFromJson,
} from '../../../hooks/geomoby/LiveMapOlFunctions';
import { MultiPolygonLayer, StrongFeatureHolder } from '../../../hooks/geomoby/useLiveMapLoader';
import { MICROFENCE_LAYER_ID } from '../BeaconUtils';
import {
  Bounds,
  FenceZone,
  GeofenceTypes,
  MicrofenceTypes,
  MicrofenceZone,
  olFenceLayers,
  olMicrofenceLayer,
} from '../MapDefaults';
import { GlobalProjectId, gpidToPath, LayerFenceData, MicrofenceData } from '../Messages';
import { GeomobyOverride } from '../types';

const DISPLAY_LIMIT = 50;

export type NameId = { name: string; id: string };
export type MicrofenceAssetId =
  | { uuid: string; major: string; minor: string }
  | { gatewayId: string }
  | { deviceId: string };

export type FenceNameIdZone = {
  name: string;
  id: string;
  type: GeofenceTypes | MicrofenceTypes;
  zone?: FenceZone;
  geomobyProperties: Record<string, string>;
  geomobyOverrides?: GeomobyOverride[];
  assetId: MicrofenceAssetId;
  parentId?: string;
  numberOfArrows?: number;
};

// GETTING LAYERS
export const getVectorLayers = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<Map<string, { layer: VectorLayer<VectorSource<Geometry>>; name: string }>> => {
  const layers = await axios.get<
    {
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }[]
  >(
    // Pushed the extentInDegrees to its maximum for now. To speed up the loading.
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent?latitude=${
      bounds.latitude
    }&longitude=${bounds.longitude}&extentInDegrees=${1}&maxPoints=100`,
    requestConfig,
  );
  const lrs: LayerResult[] = layers.data.map(({ id, name, polygons, multipolygons, lines }) =>
    layerFromJson(
      id,
      name,
      [
        ...polygons,
        ...multipolygons.map(m => {
          return {
            ...m,
            zone: FenceZone.cleared,
          };
        }),
        ...lines,
      ],
      true,
    ),
  );
  const m = new Map<
    string,
    { layer: StrongFeatureHolder<Polygon, LayerFenceData>[]; name: string }
  >(lrs.map(({ lid, name, layer }) => [lid, { layer, name }]));
  return olFenceLayers(m);
};

export const getVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  layerId: string,
  bounds: Bounds,
): Promise<option.Option<{ name: string; layer: VectorLayer<VectorSource<Geometry>> }>> => {
  try {
    const { data: layer } = await axios.get<{
      id: string;
      name: string;
      polygons: { id: string; name: string; points: GeoJSONPolygon }[];
      multipolygons: { id: string; name: string; points: GeoJSONPolygon }[];
      lines: { id: string; name: string; points: GeoJSONPolygon }[];
    }>(
      `${triggersUrl}/${gpid.cid}/${gpid.pid}/geofences/withinExtent/${layerId}?latitude=${bounds.latitude}&longitude=${bounds.longitude}&extentInDegrees=${bounds.extentInDegrees}&maxPoints=100`,
      requestConfig,
    );
    const lr: LayerResult = layerFromJson(
      layerId,
      layer.name,
      [
        ...layer.polygons,
        ...layer.multipolygons.map(m => {
          return {
            ...m,
            zone: FenceZone.cleared,
          };
        }),
        ...layer.lines,
      ],
      true,
    );
    const m = new Map<
      string,
      { name: string; layer: StrongFeatureHolder<Polygon, LayerFenceData>[] }
    >([[lr.lid, { name: layer.name, layer: lr.layer }]]);

    const res = olFenceLayers(m);
    return option.fromNullable(res.get(layerId));
  } catch (error) {
    return option.none;
  }
};

export const paginateGeofences = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  layerId: string | null,
  page: number,
  searchString: string,
) => {
  if (!layerId || layerId.includes('fresh') || layerId.includes(MICROFENCE_LAYER_ID))
    return { geofences: [], count: 0 };
  const { geofences, count } = (
    await axios.get<{ geofences: FenceNameIdZone[]; count: number }>(
      `${triggersUrl}/${gpid.cid}/${
        gpid.pid
      }/geofences/paginate/${page}?layerId=${layerId}&perPage=${DISPLAY_LIMIT}${
        searchString ? `&searchName=` + encodeURIComponent(searchString) : ''
      }`,
      requestConfig,
    )
  ).data;
  return { geofences, count };
};

export const getMicrofencesVectorLayer = async (
  triggersUrl: string,
  requestConfig: AxiosRequestConfig,
  gpid: GlobalProjectId,
  bounds: Bounds,
): Promise<AnimatedCluster> => {
  const { data: microfences } = await axios.get<MicrofenceData[]>(
    `${triggersUrl}/${gpid.cid}/${gpid.pid}/microfences`,
    requestConfig,
  );
  const { microfenceCluster } = microfenceClusterFromJson(MICROFENCE_LAYER_ID, microfences, true);
  return olMicrofenceLayer(microfenceCluster, new Map(), { showActivity: false });
};

// STYLING
export const setSelectedLayerStyle = (
  old: option.Option<{
    layer: VectorLayer<VectorSource<Geometry>>;
    name: string;
  }>,
  now: option.Option<{
    layer: VectorLayer<VectorSource<Geometry>>;
    name: string;
  }>,
  selectedVectorLayerStyle: StyleFunction,
) => {
  const layers = sequenceT(option.option)(old, now);
  const res = pipe(
    layers,
    option.map(([oldL, newL]) => {
      newL.layer.setStyle(selectedVectorLayerStyle);
      return null;
    }),
  );
  if (option.isSome(res)) return;

  pipe(
    now,
    option.map(o => o.layer.setStyle(selectedVectorLayerStyle)),
  );
};
export const setSelectedFenceStyle = (
  old: option.Option<Feature<Geometry>>,
  now: option.Option<Feature<Geometry>>,
  selectedFenceStyle: StyleFunction,
) => {
  const fences = sequenceT(option.option)(old, now);
  const res = pipe(
    fences,
    option.map(([oldF, newF]) => {
      if (oldF === newF) return null;
      oldF.setStyle(undefined);
      newF.setStyle(selectedFenceStyle);
      return null;
    }),
  );
  if (option.isSome(res)) return;

  pipe(
    old,
    option.map(o => o.setStyle(undefined)),
  );
  pipe(
    now,
    option.map(o => o.setStyle(selectedFenceStyle)),
  );
};

export const updateMicroFenceIds = (source: VectorSource<Geometry>): FenceNameIdZone[] => {
  const features = source.getFeatures();
  const microfenceIds: FenceNameIdZone[] = pipe(
    features,
    array.filterMap(f =>
      option.fromNullable({
        id: f.getProperties()['id'],
        name: f.getProperties()['name'],
        type: f.get('microfenceType') ?? f.getGeometry()?.getType().toLowerCase(),
        zone: f.getProperties()['zone'],
        geomobyProperties: f.getProperties()['geomobyProperties'],
        assetId: f.getProperties()['assetId'],
      }),
    ),
  );
  return microfenceIds.sort((a, b) => a.name.localeCompare(b.name));
};

export const updateFenceOnMap = (
  source: VectorSource<Geometry>,
  layerChanged: () => void,
  id: string,
  name: string,
  fenceZone: FenceZone | undefined,
  assetId?: MicrofenceAssetId,
  microfenceZone?: MicrofenceZone,
) => {
  const f = pipe(
    // If a feat named `old` exists, this will be it... Maybe?
    option.some(source),
    option.map((source: VectorSource<Geometry>) => source.getFeatures()),
    option.map<Feature<Geometry>[], Feature<Geometry>[]>(fs =>
      pipe(
        fs,
        array.filterMap(f => {
          const fid = option.fromNullable(f.getProperties()['id']);
          return pipe(
            fid,
            option.chain(fid => {
              if (fid === id) return option.some(f);
              return option.none;
            }),
          );
        }),
      ),
    ),
    option.chain(fs => {
      if (fs.length > 0) return option.some(fs[0]);
      return option.none;
    }),
  );
  if (option.isNone(f)) return;

  f.value.setProperties({ ...f.value.getProperties(), name, updated: true });
  if (assetId) {
    f.value.set('zone', microfenceZone);
    f.value.set('assetId', assetId);
  }
  if (fenceZone) {
    f.value.set('zone', fenceZone);
  }
  layerChanged();
};
