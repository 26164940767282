import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useAtomValue } from 'jotai';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
} from 'react';
import olMap from 'ol/Map';
import { AUTHED_REQUEST_CONFIG } from '../../../../store/auth';
import { TRIGGERS_URL } from '../../../../store/url';
import { CID, PID } from '../../../../store/user';
import { MICROFENCE, MICROFENCE_LAYER_ID, MICROFENCE_LAYER_LABEL } from '../../BeaconUtils';
import {
  GeofenceFilter,
  LayerFilter,
  MicrofenceFilter,
  MicrofenceType,
  SearchType,
  SearchTypeValue,
  FenceZoneTypeValue,
  FenceZoneType,
  MicrofenceZoneTypeValue,
  IBeacon,
  GeomobyOverride,
} from '../../types';
import { SearchTypes, FenceZoneTypes, MicrofenceZoneTypes } from '../../values';
import Polygon from 'ol/geom/Polygon';
import { MicrofenceData } from '../../Messages';
import { Geometry, LineString, MultiPolygon, Point } from 'ol/geom';
import { GridRowData } from '@material-ui/data-grid';
import {
  Assignment,
  Build,
  Cancel,
  CheckCircle,
  Clear,
  DashboardOutlined,
  Delete,
  Download,
  Edit,
  FilterList,
  LocationOn,
  NearMe,
  Search,
  Shield,
  ShieldOutlined,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { CenteredProgress } from '../../../../Common/Sidebar';
import { Extent } from 'ol/extent';
import { none, some } from 'fp-ts/es6/Option';
import { transformExtent } from 'ol/proj';
import { Feature } from 'ol';
import { fromNullable, toNullable, Option, isNone, isSome } from 'fp-ts/es6/Option';
import {
  LocationDisplayType,
  LocationSearch,
  LocationSearchData,
} from '../../Toolbar/LocationSearch';
import { Map as OlMap } from 'ol';
import { lineLimitStyle, truncationStyle } from '../../AssetRenderer';
import { useMobile } from '../../../../util/useMobile';
import { option } from 'fp-ts';
import InputContainer from '../../../Global/InputContainer';
import { getFenceLabel } from '../../ControlPanels/labelling';
import {
  BufferShapeType,
  DrawType,
  EditType,
  FenceZone,
  MicrofenceZone,
  ReassignedFence,
} from '../../MapDefaults';
import VectorSource from 'ol/source/Vector';
import { displayAssetId, getFenceIcon, getMicroFenceIcon } from '../../LiveMapSearch';
import { DEFAULT_BUFFER_METERS, FRESH, FRESH_UNKNOWN_LAYER } from '../GeofenceEditor';
import { MicrofenceAssetId, NameId } from '../GeofenceEditorFunctions';
import { debounce } from 'lodash';
import { GeoJSON } from 'ol/format';
import VectorLayer from 'ol/layer/Vector';
import { EntityType, FenceGeometryType } from '../../../../util/enums';
import { GeomobyProperties, PropertiesTable } from './Geofence/Edit/GeomobyProperties';
import { GeomobyOverrides, OverridesTable } from './Geofence/Edit/GeomobyOverrides';

const LoadingCircle = () => {
  return (
    <Grid
      container
      justifyContent={'center'}
      style={{
        marginTop: '100px',
      }}
    >
      <CenteredProgress />
    </Grid>
  );
};

export const GeofenceEditorSearch = ({
  layerIds,
  setLayerIds,
  layers,
  setLayers,
  availableGeofences,
  setAvailableGeofences,
  layersFromMap,
  freshGeofences,
  setFreshGeofences,
  availableMicrofences,
  setAvailableMicrofences,
  microfences,
  selectedGeofence,
  setSelectedGeofence,
  selectedMicrofence,
  setSelectedMicrofence,
  setExtent,
  searchType,
  setSearchType,
  layerFilter,
  setLayerFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  showGhostGeofences,
  setShowGhostGeofences,
  createEditFence,
  setCreateEditFence,
  createEditLayer,
  setCreateEditLayer,
  refreshSearch,
  setRefreshSearch,
  setLocationSearchData,
  currentCenter,
  locationDisplay,
  setLocationDisplay,
  setDrawType,
  layersHaveChanged,
  setLayersHaveChanged,
  selectedLayer,
  setSelectedLayer,
  setRenamingLayer,
  reassignedFences,
  setReassignedFences,
  setDirtySave,
  dirtySave,
  openGenericDialog,
  setOpenGenericDialog,
  isLoading,
  setIsLoading,
  mapIsLoading,
  setNavigateTo,
  selectedFromMap,
  setSelectedFromMap,
  count,
  setCount,
  hasFences,
  deselectFence,
  setDeselectFence,
  displayGeomobyOverrides,
  setDisplayGeomobyOverrides,
  displayGeomobyProperties,
  setDisplayGeomobyProperties,
  createNewLayer,
  deleteLayer,
  deleteFence,
  changeVisibility,
  editing,
  setEditing,
  unsetEditing,
  deselectAllFences,
  moveUnknownFenceToExistingLayer,
  resetLayerChanges,
  saveLayerChanges,
  updateFenceIdentifiers,
  updateGeomobyProperties,
  updateGeomobyOverrides,
  setAsBufferZone,
  removeBufferZone,
  setAsBreachZone,
  unsetAsBreachZone,
  setZone,
  unsetZone,
}: {
  layerIds: NameId[];
  setLayerIds: Dispatch<SetStateAction<NameId[]>>;
  layers: Option<Map<string, { source: VectorSource<Geometry>; name: string }>>;
  setLayers: Dispatch<
    SetStateAction<Option<Map<string, { source: VectorSource<Geometry>; name: string }>>>
  >;
  availableGeofences: GridRowData[];
  setAvailableGeofences: Dispatch<SetStateAction<GridRowData[]>>;
  layersFromMap: VectorLayer<VectorSource<Geometry>>[];
  freshGeofences: GridRowData[];
  setFreshGeofences: Dispatch<SetStateAction<GridRowData[]>>;
  availableMicrofences: GridRowData[];
  setAvailableMicrofences: Dispatch<SetStateAction<GridRowData[]>>;
  microfences: Feature<Geometry>[];
  selectedGeofence: GridRowData | undefined;
  setSelectedGeofence: Dispatch<GridRowData | undefined>;
  selectedMicrofence: GridRowData | undefined;
  setSelectedMicrofence: Dispatch<GridRowData | undefined>;
  setExtent: Dispatch<SetStateAction<Option<Extent>>>;
  searchType: SearchType | undefined;
  setSearchType: Dispatch<SearchType | undefined>;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  geofenceFilter: GeofenceFilter;
  setGeofenceFilter: Dispatch<GeofenceFilter>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  showGhostGeofences: boolean;
  setShowGhostGeofences: Dispatch<boolean>;
  createEditFence: 'CREATE' | 'EDIT' | undefined;
  setCreateEditFence: Dispatch<'CREATE' | 'EDIT' | undefined>;
  createEditLayer: 'CREATE' | 'EDIT' | undefined;
  setCreateEditLayer: Dispatch<'CREATE' | 'EDIT' | undefined>;
  refreshSearch: boolean;
  setRefreshSearch: Dispatch<boolean>;
  setLocationSearchData: Dispatch<SetStateAction<LocationSearchData | undefined>>;
  currentCenter: number[] | undefined;
  locationDisplay: LocationDisplayType | undefined;
  setLocationDisplay: Dispatch<SetStateAction<LocationDisplayType | undefined>>;
  setDrawType: Dispatch<SetStateAction<option.Option<DrawType>>>;
  layersHaveChanged: boolean;
  setLayersHaveChanged: Dispatch<SetStateAction<boolean>>;
  selectedLayer: option.Option<NameId>;
  setSelectedLayer: (l: option.Option<string>) => void;
  setRenamingLayer: Dispatch<SetStateAction<string | null>>;
  reassignedFences: ReassignedFence[];
  setReassignedFences: Dispatch<SetStateAction<ReassignedFence[]>>;
  setDirtySave: Dispatch<
    SetStateAction<{
      isDirty: boolean;
      issue: string | null;
    }>
  >;
  dirtySave: { isDirty: boolean; issue: string | null };
  openGenericDialog: boolean;
  setOpenGenericDialog: Dispatch<boolean>;
  isLoading: boolean;
  setIsLoading: Dispatch<boolean>;
  mapIsLoading: boolean;
  setNavigateTo: Dispatch<SetStateAction<string | null>>;
  selectedFromMap: boolean;
  setSelectedFromMap: Dispatch<boolean>;
  count: number;
  setCount: Dispatch<SetStateAction<number>>;
  hasFences: boolean;
  deselectFence: boolean;
  setDeselectFence: Dispatch<boolean>;
  displayGeomobyOverrides: (GeomobyOverride & { index: number })[];
  setDisplayGeomobyOverrides: Dispatch<SetStateAction<(GeomobyOverride & { index: number })[]>>;
  displayGeomobyProperties: { index: number; property: string; value: string }[];
  setDisplayGeomobyProperties: Dispatch<
    SetStateAction<{ index: number; property: string; value: string }[]>
  >;

  createNewLayer: (newLayer: string) => string;
  deleteLayer: () => Promise<void>;
  deleteFence: (fence: GridRowData, type: FenceGeometryType | undefined) => Promise<void>;
  changeVisibility: (lid: string, visible: boolean, opacity?: number) => void;
  editing: boolean;
  setEditing: () => void;
  unsetEditing: () => void;
  deselectAllFences: () => void;
  moveUnknownFenceToExistingLayer: (layerId: string) => void;
  resetLayerChanges: () => Promise<void>;
  saveLayerChanges: () => Promise<void>;
  updateFenceIdentifiers: (
    id: string,
    name: string,
    type: FenceGeometryType | undefined,
    layerId: string,
    fenceZone: FenceZone | undefined,
    assetId: MicrofenceAssetId | undefined,
    microfenceZone: MicrofenceZone | undefined,
  ) => Promise<void>;
  updateGeomobyProperties: (
    geomobyProperties: Record<string, string>,
  ) => Promise<Record<string, string> | undefined>;
  updateGeomobyOverrides: (
    geomobyProperties: GeomobyOverride[],
  ) => Promise<GeomobyOverride[] | undefined>;
  setAsBufferZone: (fence: GridRowData) => Promise<void>;
  removeBufferZone: (fence: GridRowData) => Promise<void>;
  setAsBreachZone: (
    fence: GridRowData,
    bufferId: string | undefined,
    offset: number,
    bufferShape: BufferShapeType,
  ) => Promise<void>;
  unsetAsBreachZone: (fence: GridRowData, bufferId: string) => Promise<void>;
  setZone: (fence: GridRowData, zone: FenceZoneType['id']) => Promise<void>;
  unsetZone: (fence: GridRowData) => Promise<void>;
}) => {
  const cid = useAtomValue(CID);
  const pid = useAtomValue(PID);
  const authedConfig = useAtomValue(AUTHED_REQUEST_CONFIG);
  const triggersUrl = useAtomValue(TRIGGERS_URL);

  const [bufferOffset, setBufferOffset] = useState<number | undefined>();
  const [bufferShape, setBufferShape] = useState<BufferShapeType>('Circle');
  const [deleting, setDeleting] = useState<'LAYER' | 'GEOFENCE' | 'MICROFENCE' | undefined>();
  const [fenceNameInput, setFenceNameInput] = useState<string>('');
  const [layerNameInput, setLayerNameInput] = useState<string>('');
  const [layerConflict, setLayerConflict] = useState<boolean>(false);
  const [microfenceIdInput, setMicrofenceIdInput] = useState<string>('');
  const [microfenceIBeaconIdentifier, setMicrofenceIBeaconIdentifier] = useState<IBeacon>({
    uuid: '',
    major: 0,
    minor: 0,
  });
  const [microfenceZoneInput, setMicrofenceZoneInput] = useState<MicrofenceZone | undefined>();
  const [properties, setProperties] = useState<{ label: string; id: string; isCustom: boolean }[]>(
    [],
  );
  const [reassigningToLayer, setReassigningToLayer] = useState<boolean>(false);
  const [reassignedLayerId, setReassignedLayerId] = useState<string>('');

  const isMobile = useMobile();
  const bufferOffsetRef = useRef<HTMLInputElement>(null);
  const deletedFenceIdsRef = useRef<string[]>([]);
  const fenceNameRef = useRef<HTMLInputElement>(null);
  const layerNameRef = useRef<HTMLInputElement>(null);
  const microfenceIdRef = useRef<HTMLInputElement>(null);
  const microfenceUUIDRef = useRef<HTMLInputElement>(null);
  const microfenceMajorRef = useRef<HTMLInputElement>(null);
  const microfenceMinorRef = useRef<HTMLInputElement>(null);
  const pageRef = useRef<number>(1);

  const isLine = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('line') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('line'),
    [selectedGeofence],
  );
  const isPolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase() === 'polygon' ||
      selectedGeofence?.points?.type?.toLowerCase() === 'polygon',
    [selectedGeofence],
  );
  const isMultipolygon = useMemo(
    () =>
      selectedGeofence?.type?.toLowerCase()?.includes('multipolygon') ||
      selectedGeofence?.points?.type?.toLowerCase()?.includes('multipolygon'),
    [selectedGeofence],
  );
  const fenceType = useMemo(
    () =>
      isPolygon
        ? FenceGeometryType.Polygon
        : isMultipolygon
        ? FenceGeometryType.Multipolygon
        : isLine
        ? FenceGeometryType.Line
        : selectedMicrofence
        ? FenceGeometryType.Microfence
        : undefined,
    [selectedMicrofence, isPolygon, isMultipolygon, isLine],
  );

  const getGeofence = useCallback(
    async (fence: GridRowData) => {
      if (!selectedGeofence) return;
      setSelectedMicrofence(undefined);
      const type = isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line';
      if (!type) return;

      const geofence = (
        await axios.get<{
          id: string;
          name: string;
          points: { coordinates: Extent };
          geomobyProperties: Record<string, string>;
          geomobyOverrides: GeomobyOverride[];
        }>(
          `${triggersUrl}/${cid}/${pid}/geofences/${fence.layerId}/${type}/${fence.id}`,
          authedConfig,
        )
      ).data;

      if (!geofence) return;
      const newFence = isPolygon
        ? new Polygon(geofence.points.coordinates)
        : isMultipolygon
        ? new MultiPolygon(geofence.points.coordinates)
        : new LineString(geofence.points.coordinates);

      const extent = transformExtent(newFence.getExtent(), 'EPSG:4326', 'EPSG:3857');
      setExtent(some(extent));
      return geofence;
    },
    [
      cid,
      pid,
      triggersUrl,
      authedConfig,
      setExtent,
      setSelectedMicrofence,
      selectedGeofence,
      isPolygon,
      isMultipolygon,
    ],
  );

  const paginateGeofences = useCallback(
    async (filter: GeofenceFilter, refresh?: boolean) => {
      if (layerIds.length === 0) {
        setAvailableGeofences([]);
        setCount(0);
        return;
      }

      const filterAllLayers = filter?.layerId === 'ALL';
      if (filterAllLayers) {
        filter.layerId = undefined;
      }
      setCount(0);
      if (pageRef.current === 1) {
        setIsLoading(true);
      }

      let filters = `?perPage=${filter.perPage}`;
      Object.entries(filter).map(([key, value]) => {
        if (key !== undefined && value !== undefined && key !== 'perPage') {
          filters = filters.concat(
            `&${key}=${typeof value === 'object' ? value.id : value.toString()}`,
          );
        }
      });

      if (layerIds.length === 1 && layerIds[0].id === MICROFENCE_LAYER_ID) {
        setIsLoading(false);
        return;
      }
      const { geofences, count } = (
        await axios.get<{ geofences: GridRowData[]; count: number }>(
          `${triggersUrl}/${cid}/${pid}/geofences/paginate/${pageRef.current}${filters}`,
          authedConfig,
        )
      ).data;

      const currentLayerFromMap = layersFromMap.find(
        l => l.getSource().get('id') === filter.layerId,
      ) as VectorLayer<VectorSource<Geometry>>;
      const fencesFromMap = geofences.map(fence => {
        const fenceFromMap: Feature<Geometry> | undefined = currentLayerFromMap
          ?.getSource()
          .getFeatures()
          .find(f => f.get('id') === fence.id);
        return fenceFromMap?.getProperties() ?? fence;
      });

      let result = [];
      if (pageRef.current === 1) {
        if (filter.layerId && reassignedFences.length > 0 && !refresh) {
          result = fencesFromMap.filter(fence => {
            return (
              fence.layerId === filter.layerId &&
              !reassignedFences.find(f => f.id === fence.id) &&
              !deletedFenceIdsRef.current.find(id => id === fence.id)
            );
          });
        } else {
          result = fencesFromMap.filter(
            fence => !deletedFenceIdsRef.current.find(id => id === fence.id),
          );
        }
      } else {
        const nextPage = [...availableGeofences, ...fencesFromMap];
        result = nextPage.filter(fence => !deletedFenceIdsRef.current.find(id => id === fence.id));
      }

      setAvailableGeofences(
        !refresh
          ? [
              ...result,
              ...freshGeofences.filter(
                fence => !deletedFenceIdsRef.current.find(id => id === fence.id),
              ),
            ].sort((a, b) => a.name.localeCompare(b.name))
          : result,
      );

      setCount(count);
      setIsLoading(false);
    },
    [
      cid,
      pid,
      triggersUrl,
      authedConfig,
      availableGeofences,
      layersFromMap,
      freshGeofences,
      layerIds,
      reassignedFences,
      setAvailableGeofences,
      setCount,
      setIsLoading,
    ],
  );

  const microfenceSort = useCallback((): GridRowData[] => {
    let updatedMicrofences = microfences.map(fence => fence.getProperties());
    Object.entries(microfenceFilter ?? {}).map(([key, value]) => {
      if (
        !(
          key === 'orderBy' ||
          key === 'order' ||
          key === 'microfenceProperties' ||
          typeof value === 'string'
        )
      ) {
        updatedMicrofences = updatedMicrofences.filter(
          microfence => microfence.microfenceType === (value as MicrofenceType).id,
        );
      }
      if (key === 'searchName') {
        updatedMicrofences = updatedMicrofences.filter(microfence =>
          microfence.name.includes(value),
        );
      }
      if (key === 'searchId') {
        updatedMicrofences = updatedMicrofences.filter(microfence => {
          return (
            (microfence.microfenceType === 'beacon' && microfence.assetId.uuid.includes(value)) ||
            ((microfence.microfenceType === 'gateway' ||
              microfence.microfenceType === 'smartplug') &&
              (microfence.assetId.gatewayId.includes(value) ||
                microfence.assetId.smartplugId.includes(value))) ||
            (microfence.microfenceType === 'device' && microfence.assetId.deviceId.includes(value))
          );
        });
      }
      if (key === 'microfenceProperties') {
        updatedMicrofences = updatedMicrofences.filter(microfence => {
          const found = Object.entries(microfence.geomobyProperties).find(([k, v]) => {
            if (microfenceFilter?.microfenceProperties?.operator?.id === 'EQUAL_TO') {
              return (
                k === microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) === microfenceFilter?.microfenceProperties?.value
              );
            } else if (microfenceFilter?.microfenceProperties?.operator?.id === 'GREATER_THAN') {
              return (
                k === microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) > microfenceFilter?.microfenceProperties?.value
              );
            } else {
              return (
                k === microfenceFilter?.microfenceProperties?.type?.value &&
                Number(v) < microfenceFilter?.microfenceProperties?.value
              );
            }
          });
          if (!found) return;
          return microfence;
        });
      }
    });

    updatedMicrofences = updatedMicrofences.filter(
      microfence => !deletedFenceIdsRef.current.find(d => d === microfence.id),
    );

    updatedMicrofences =
      microfenceFilter?.order?.id === 'DESC'
        ? updatedMicrofences.sort((a, b) => {
            if (microfenceFilter?.orderBy?.id === 'microfenceType') {
              return String(b.microfenceType).localeCompare(String(a.microfenceType));
            } else {
              return String(b.name).localeCompare(String(a.name));
            }
          })
        : updatedMicrofences.sort((a, b) => {
            if (microfenceFilter?.orderBy?.id === 'microfenceType') {
              return String(a.microfenceType).localeCompare(String(b.microfenceType));
            } else {
              return String(a.name).localeCompare(String(b.name));
            }
          });

    setAvailableMicrofences(updatedMicrofences);
    return updatedMicrofences;
  }, [microfenceFilter, microfences, setAvailableMicrofences]);

  const getGeofenceProperties = useCallback(async () => {
    const defaultProperties = (
      await axios.get<{ label: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/geomoby`,
        authedConfig,
      )
    ).data;
    const projectProperties = (
      await axios.get<{ label: string; id: string }[]>(
        `${triggersUrl}/${cid}/${pid}/geofences/properties/project`,
        authedConfig,
      )
    ).data;

    setProperties(
      [
        ...defaultProperties.map(p => {
          return { id: p.label, label: p.label, isCustom: false };
        }),
        ...projectProperties.map(p => {
          return {
            ...p,
            isCustom: true,
          };
        }),
      ].sort((a, b) => a.label.localeCompare(b.label)),
    );
  }, [cid, pid, triggersUrl, authedConfig]);

  const refreshFilter = useCallback(() => {
    setAvailableGeofences([]);
    microfenceSort();
    setCount(0);
    setGeofenceFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    pageRef.current = 1;

    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences({ perPage: 50, layerId: layerFilter?.layer?.id } as GeofenceFilter);
    }
  }, [
    microfenceSort,
    layerFilter,
    paginateGeofences,
    setGeofenceFilter,
    setMicrofenceFilter,
    searchType,
    setAvailableGeofences,
    setCount,
  ]);

  const debounceUpdateMicrofence = useRef(
    debounce(
      async (
        id: string,
        name: string,
        microfenceZone: MicrofenceZone | undefined,
        assetId: MicrofenceAssetId | undefined,
      ) => {
        await updateFenceIdentifiers(
          id,
          name,
          FenceGeometryType.Microfence,
          MICROFENCE_LAYER_ID,
          undefined,
          assetId,
          microfenceZone,
        );
      },
      500,
      { leading: true },
    ),
  ).current;

  const debounceUpdateGeofence = useRef(
    debounce(
      async (
        id: string,
        name: string,
        type: FenceGeometryType | undefined,
        layerId: string,
        zone: FenceZone | undefined,
      ) => {
        await updateFenceIdentifiers(id, name, type, layerId, zone, undefined, undefined);
      },
      500,
      { leading: true },
    ),
  ).current;

  const clearAll = () => {
    setAvailableGeofences([]);
    microfenceSort();
    setCount(0);
    setGeofenceFilter({ perPage: 50 });
    setMicrofenceFilter(undefined);
    setLayerFilter(undefined);
    setSelectedLayer(none);
    pageRef.current = 1;
    setSearchType(undefined);
    deletedFenceIdsRef.current = [];
  };

  const saveLayer = () => {
    if (
      (isSome(selectedLayer) &&
        layerIds.find(lyr => lyr.name === layerNameInput && lyr.id !== selectedLayer.value.id)) ||
      (isNone(selectedLayer) && layerIds.find(lyr => lyr.name === layerNameInput))
    ) {
      setLayerConflict(true);
      return;
    }

    if (createEditLayer === 'CREATE') {
      // New Layer
      const id = createNewLayer(layerNameInput);
      layerIds
        ?.filter(layer => layer.id !== id)
        .forEach(layer => changeVisibility(layer.id, false));
      changeVisibility(id, true);
      setSelectedLayer(fromNullable(id));
      setAvailableGeofences([]);
    } else {
      // Existing Layer
      if (isSome(selectedLayer) && !selectedLayer.value.id.includes(FRESH)) {
        selectedLayer.value.name = layerNameInput;
        setRenamingLayer(selectedLayer.value.name);
        setLayersHaveChanged(true);
        setLayerFilter({
          ...layerFilter,
          layer: selectedLayer.value,
        });
        pageRef.current = 1;
        paginateGeofences(
          { ...geofenceFilter, layerId: selectedLayer.value.id } as GeofenceFilter,
          true,
        );
      } else {
        // Updating a new one
        if (isSome(selectedLayer) && selectedLayer.value.id.includes(FRESH)) {
          changeVisibility(String(selectedLayer.value.id), true);
          setSelectedLayer(some(selectedLayer.value.id));
          selectedLayer.value.name = layerNameInput;
          if (isSome(layers)) {
            layers.value.forEach((vl, id) => {
              if (id === selectedLayer.value.id) {
                vl.name = layerNameInput;
              }
            });
          }
          setLayerFilter({
            ...layerFilter,
            layer: selectedLayer.value,
          });
          pageRef.current = 1;
          paginateGeofences(
            {
              ...geofenceFilter,
              layerId: selectedLayer.value.id,
            } as GeofenceFilter,
            true,
          );
        }
      }
    }
  };

  const saveAllChanges = async () => {
    setDrawType(none);
    if (layerIds.find(lyr => lyr.id === FRESH_UNKNOWN_LAYER)) {
      setLayerIds(layerIds.filter(lyr => lyr.id !== FRESH_UNKNOWN_LAYER));
    }

    await saveLayerChanges();
    if (editing && !dirtySave.isDirty) unsetEditing();

    pageRef.current = 1;
    cancelLayer();
    setReassignedFences([]);
    setCreateEditLayer(undefined);
    setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setReassignedLayerId('');
    setLayerNameInput('');
    microfenceSort();
    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences(
        {
          ...geofenceFilter,
          layerId: isSome(selectedLayer) ? selectedLayer.value?.id : 'ALL',
        } as GeofenceFilter,
        true,
      );
    }
  };

  const cancelLayer = () => {
    setCreateEditLayer(undefined);
    setLayerNameInput('');
  };

  const cancelGeofence = useCallback(async () => {
    setCreateEditFence(undefined);
    setReassigningToLayer(false);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceZoneInput(undefined);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape('Circle');

    const bufferId = availableGeofences?.find(f => f.parentId === selectedGeofence?.id)?.id;
    if (selectedGeofence?.zone === FenceZone.breach && bufferId) {
      unsetAsBreachZone(selectedGeofence, bufferId);
    } else if (selectedGeofence?.zone === FenceZone.cleared) {
      unsetZone(selectedGeofence);
    }

    if (selectedGeofence && !selectedGeofence?.id?.includes(FRESH)) {
      const { id, layerId, type } = selectedGeofence;
      const selectedType = isPolygon ? 'polygon' : isMultipolygon ? 'multipolygon' : 'line';
      if (!selectedType) return;

      const oldFence = (
        await axios.get<GridRowData>(
          `${triggersUrl}/${cid}/${pid}/geofences/${layerId}/${selectedType}/${id}`,
          authedConfig,
        )
      ).data;
      setDisplayGeomobyProperties(
        Object.entries(oldFence.geomobyProperties).map(([property, value], index) => {
          return {
            index: index as number,
            property: property as string,
            value: value as string,
          };
        }),
      );
      setDisplayGeomobyOverrides(oldFence.geomobyOverrides);
      setSelectedGeofence({
        ...oldFence,
        layerId,
        type,
      });
    }
  }, [
    authedConfig,
    cid,
    pid,
    selectedGeofence,
    setCreateEditFence,
    setSelectedGeofence,
    triggersUrl,
    isPolygon,
    isMultipolygon,
    unsetAsBreachZone,
    unsetZone,
    setDisplayGeomobyOverrides,
    setDisplayGeomobyProperties,
    availableGeofences,
  ]);

  const cancelMicrofence = useCallback(() => {
    setCreateEditFence(undefined);
    setFenceNameInput('');
    setMicrofenceIdInput('');
    setMicrofenceIBeaconIdentifier({
      uuid: '',
      major: 0,
      minor: 0,
    });
    setMicrofenceZoneInput(undefined);
  }, [
    setCreateEditFence,
    setFenceNameInput,
    setMicrofenceIdInput,
    setMicrofenceIBeaconIdentifier,
    setMicrofenceZoneInput,
  ]);

  const cancelAllChanges = async () => {
    pageRef.current = 1;
    deletedFenceIdsRef.current = [];
    setDrawType(none);
    if (editing) {
      unsetEditing();
    }
    cancelLayer();
    await resetLayerChanges();
    cancelGeofence();
    cancelMicrofence();
    setReassignedFences([]);
  };

  const goBack = () => {
    deselectAllFences();
    cancelMicrofence();
    setSelectedGeofence(undefined);
    setReassignedLayerId('');
    setBufferOffset(undefined);
    setBufferShape('Circle');
    setSelectedMicrofence(undefined);

    if (!layerFilter) {
      setSearchType(undefined);
      layerIds?.forEach(layer => changeVisibility(layer.id, true));
      setSelectedLayer(none);
      return;
    }

    if (searchType?.id === 'GEOFENCES') {
      paginateGeofences({
        ...geofenceFilter,
        layerId: isSome(selectedLayer) ? selectedLayer.value?.id : 'ALL',
      } as GeofenceFilter);
    } else if (searchType?.id === 'MICROFENCES') {
      microfenceSort();
    }
  };

  const getZonesSelection = (): FenceZoneType[] => {
    if (
      selectedGeofence?.zone === FenceZone.breach ||
      selectedGeofence?.zone === FenceZone.cleared
    ) {
      return FenceZoneTypes.filter(
        zone => zone.id === selectedGeofence.zone || zone.id === FenceZone.none,
      );
    } else if (hasFences) {
      return FenceZoneTypes;
    }
    return FenceZoneTypes.filter(f => f.id !== FenceZone.buffer);
  };

  const resetlayer = () => {
    if (selectedGeofence) {
      setLayerFilter({
        layer: {
          name: layerIds.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? '',
          id: selectedGeofence.layerId,
        },
      });
      setSelectedLayer(fromNullable(selectedGeofence.layerId));
      paginateGeofences(
        {
          ...geofenceFilter,
          layerId: selectedGeofence.layerId,
          perPage: 50,
        } as GeofenceFilter,
        true,
      );
    } else if (selectedMicrofence) {
      setLayerFilter({
        ...layerFilter,
        layer: { name: 'Microfences', id: MICROFENCE_LAYER_ID },
      });
      setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
      setAvailableMicrofences(microfences.map(fence => fence.getProperties()));
    }
  };

  const downloadGeoJSON = async () => {
    if (!selectedGeofence) return;
    const geofence = await getGeofence(selectedGeofence);
    const type = isPolygon
      ? 'Polygon'
      : isMultipolygon
      ? 'MultiPolygon'
      : isLine
      ? 'LineString'
      : undefined;
    if (!geofence?.points || !type) return;

    const layerId = selectedGeofence.layerId ?? toNullable(selectedLayer)?.id;
    const layerName = layerIds.find(lyr => lyr.id === layerId)?.name;
    const geoJSON = {
      type: 'Feature',
      geometry: {
        type,
        coordinates: geofence.points.coordinates,
      },
      properties: {
        id: geofence.id,
        name: geofence.name,
        geomobyProperties: geofence.geomobyProperties,
        geomobyOverrides: geofence.geomobyOverrides,
        zone: selectedGeofence.zone,
        parentId: selectedGeofence.parentId,
        layerId,
        layerName,
      },
    };

    const jsonString: string = JSON.stringify(geoJSON, null, 2);
    const dataUri = window.URL.createObjectURL(
      new Blob([jsonString], { type: 'application/json' }),
    );
    const link = document.createElement('a');
    link.download = `${selectedGeofence.name}.json`;
    link.href = dataUri;
    link.click();
  };

  const updateAssetId = async (value: string) => {
    if (!selectedMicrofence) return;
    setMicrofenceIdInput(value);
    let assetId;
    if (selectedMicrofence?.assetId?.deviceId !== undefined) {
      assetId = {
        deviceId: value,
      };
    } else if (
      selectedMicrofence?.assetId?.gatewayId !== undefined ||
      selectedMicrofence?.assetId?.smartplugId !== undefined
    ) {
      assetId = {
        gatewayId: value,
      };
    }

    setSelectedMicrofence({
      ...selectedMicrofence,
      assetId,
    });
    debounceUpdateMicrofence(
      selectedMicrofence.id,
      selectedMicrofence.name,
      selectedMicrofence.zone,
      assetId,
    );
  };

  const findBufferFromBreach = (breachId: string): string | undefined => {
    return availableGeofences?.find(f => f.parentId === breachId)?.id;
  };

  useEffect(() => {
    getGeofenceProperties();
  }, [getGeofenceProperties]);

  // Created a new layer.
  useEffect(() => {
    if (!createEditLayer) return;
    layerNameRef.current?.focus();
  }, [createEditLayer]);

  // Created a new Geofence or Microfence.
  useEffect(() => {
    if (createEditFence !== 'CREATE') return;
    if (selectedGeofence || selectedMicrofence) {
      cancelGeofence();
      cancelMicrofence();
    }

    if (selectedGeofence) {
      if (selectedGeofence?.id.includes(FRESH)) {
        setDisplayGeomobyProperties(
          Object.entries(selectedGeofence?.geomobyProperties).map(([property, value], index) => {
            return {
              index: index as number,
              property: property as string,
              value: value as string,
            };
          }),
        );
        setDisplayGeomobyOverrides(selectedGeofence?.geomobyOverrides);
      }
    } else if (selectedMicrofence) {
      if (selectedMicrofence?.id.includes(FRESH)) {
        setDisplayGeomobyProperties(
          Object.entries(selectedMicrofence?.geomobyProperties).map(([property, value], index) => {
            return {
              index: index as number,
              property: property as string,
              value: value as string,
            };
          }),
        );
      }
    }
  }, [
    createEditFence,
    cancelGeofence,
    cancelMicrofence,
    selectedGeofence,
    selectedMicrofence,
    setDisplayGeomobyOverrides,
    setDisplayGeomobyProperties,
  ]);

  // Clicked a geofence or microfence from the map.
  useEffect(() => {
    if (selectedFromMap) {
      if (selectedGeofence) {
        setDisplayGeomobyProperties(
          Object.entries(selectedGeofence.geomobyProperties).map(([property, value], index) => {
            return {
              index: index as number,
              property: property as string,
              value: value as string,
            };
          }),
        );
        setDisplayGeomobyOverrides(selectedGeofence?.geomobyOverrides);
        setFenceNameInput(selectedGeofence.name);
        setBufferOffset(undefined);
        setBufferShape('Circle');
      }
      if (selectedMicrofence) {
        cancelMicrofence();
        setDisplayGeomobyProperties(
          Object.entries(selectedMicrofence.geomobyProperties).map(([property, value], index) => {
            return {
              index: index as number,
              property: property as string,
              value: value as string,
            };
          }),
        );
        setFenceNameInput(selectedMicrofence.name);
      }
      setSelectedFromMap(false);
    }
  }, [
    cancelGeofence,
    selectedFromMap,
    setSelectedFromMap,
    selectedGeofence,
    cancelMicrofence,
    selectedMicrofence,
    setDisplayGeomobyOverrides,
    setDisplayGeomobyProperties,
  ]);

  // Filter
  useEffect(() => {
    if (!refreshSearch) return;
    if (searchType?.id === 'GEOFENCES') {
      pageRef.current = 1;
      setSelectedLayer(fromNullable(layerFilter?.layer?.id));
      paginateGeofences(
        { ...geofenceFilter, layerId: layerFilter?.layer?.id } as GeofenceFilter,
        freshGeofences.length === 0,
      );
    }

    // TODO: This will have to do for microfence searching, until we have the correct end-point - LTP-478.
    if (searchType?.id === 'MICROFENCES') {
      setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
      microfenceSort();
    }

    setRefreshSearch(false);
    setShowFilter(false);
  }, [
    refreshSearch,
    paginateGeofences,
    searchType,
    geofenceFilter,
    layerFilter,
    setSelectedLayer,
    setRefreshSearch,
    setShowFilter,
    microfenceFilter,
    microfences,
    refreshFilter,
    setAvailableMicrofences,
    freshGeofences,
    microfenceSort,
    setDisplayGeomobyOverrides,
    setDisplayGeomobyProperties,
  ]);

  // Clear filter.
  useEffect(() => {
    if (clearFilter) {
      refreshFilter();
      setClearFilter(false);
    }
  }, [clearFilter, refreshFilter, setClearFilter]);

  useEffect(() => {
    if (deselectFence) {
      setDeselectFence(false);
      if (!layerFilter) {
        setSearchType(undefined);
        layerIds?.forEach(layer => changeVisibility(layer.id, true));
        setSelectedLayer(none);
      }
    }
  }, [
    deselectFence,
    setDeselectFence,
    setSearchType,
    layerFilter,
    layerIds,
    changeVisibility,
    setSelectedLayer,
  ]);

  return (
    <>
      <Grid
        style={{
          contentVisibility: showFilter ? 'hidden' : 'visible',
          margin: '20px',
        }}
      >
        {(selectedGeofence || selectedMicrofence) && (
          <Button
            style={{
              marginBottom: '10px',
            }}
            disabled={
              selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER ||
              selectedGeofence?.previousLayer === FRESH_UNKNOWN_LAYER
            }
            onClick={() => goBack()}
          >
            Back
          </Button>
        )}

        {/* Search type */}
        <Grid
          container
          direction="row"
          sx={
            toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID && !selectedMicrofence
              ? {
                  display: 'grid',
                  gap: isMobile ? '0%' : '2%',
                  gridTemplateColumns: isMobile ? '92% 10%' : '88% 10%',
                }
              : {}
          }
        >
          {!(selectedGeofence || selectedMicrofence) && (
            <FormControl
              fullWidth
              style={{
                width: '98%',
                marginBottom: '20px',
              }}
            >
              <InputLabel id="search-option">Search For</InputLabel>
              <Select
                fullWidth
                labelId="search-option"
                id="search-option"
                value={searchType?.value ?? ''}
                label="Search For"
                disabled={!!createEditLayer || mapIsLoading || layersHaveChanged}
                onChange={e => {
                  const search = SearchTypes.find(
                    l => l.value === (e.target.value as SearchTypeValue),
                  );
                  clearAll();
                  setSearchType(search);
                  setShowGhostGeofences(false);

                  if (search?.id === 'GEOFENCES') {
                    layerIds?.forEach(layer =>
                      changeVisibility(
                        layer.id,
                        layer.id !== MICROFENCE_LAYER_ID,
                        layer.id === MICROFENCE_LAYER_ID ? 0 : 1,
                      ),
                    );
                    setLayerFilter({
                      ...layerFilter,
                      layer: { name: 'All Groups', id: 'ALL' },
                    });
                    pageRef.current = 1;
                    paginateGeofences({ ...geofenceFilter, layerId: 'ALL' } as GeofenceFilter);
                  }
                  if (search?.id === 'MICROFENCES') {
                    microfenceSort();
                    layerIds?.forEach(layer =>
                      changeVisibility(layer.id, layer.id === MICROFENCE_LAYER_ID),
                    );
                    setSelectedLayer(fromNullable(MICROFENCE_LAYER_ID));
                    setLayerFilter({
                      ...layerFilter,
                      layer: { name: 'Microfences', id: MICROFENCE_LAYER_ID },
                    });
                  }
                  if (search?.id === 'LOCATIONS') {
                    layerIds?.forEach(layer => changeVisibility(layer.id, true, 1));
                  }
                }}
              >
                {SearchTypes.filter(search => search.forEditing).map(search => (
                  <MenuItem key={search.id} value={search.value} disabled={search.disabled}>
                    <Tooltip title={search.value}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 30px)',
                        }}
                      >
                        {search.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}

          {toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID && !selectedMicrofence && (
            <Tooltip title={'SHOW GEOFENCES'}>
              <IconButton
                style={{
                  color: showGhostGeofences ? '#4CB8C4' : '#fff',
                  justifySelf: 'right',
                  width: 'fit-content',
                  height: 'fit-content',
                  marginTop: '12px',
                }}
                onClick={() => {
                  const showAllFences = !showGhostGeofences;
                  layerIds?.forEach(layer =>
                    changeVisibility(
                      layer.id,
                      layer.id === MICROFENCE_LAYER_ID ? true : showAllFences,
                    ),
                  );
                  setShowGhostGeofences(showAllFences);
                }}
              >
                {showGhostGeofences ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        {/* Add new layer */}
        {searchType?.id === 'GEOFENCES' && !selectedGeofence && (
          <Grid
            container
            direction="column"
            style={{
              width: '98%',
            }}
          >
            <Button
              variant="outlined"
              fullWidth
              disabled={isLoading || layersHaveChanged || !!createEditLayer}
              style={{
                marginBottom: '20px',
              }}
              onClick={() => setCreateEditLayer('CREATE')}
            >
              Create New Group
            </Button>

            {createEditLayer && (
              <Grid
                container
                style={{
                  display: 'grid',
                  gridTemplateColumns: '90% 10%',
                  marginTop: '-7.5px',
                }}
              >
                <OutlinedInput
                  inputRef={layerNameRef}
                  placeholder="Group 1"
                  value={layerNameInput}
                  style={{
                    width: '98%',
                    height: '57px',
                    marginTop: '7.5px',
                  }}
                  onChange={({ target: { value } }) =>
                    setLayerNameInput(value.replaceAll(' ', '_'))
                  }
                  onKeyPress={event => {
                    if (event.key === 'Enter') saveLayer();
                  }}
                />

                <Grid
                  item
                  container
                  direction="row"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <IconButton onClick={() => saveLayer()} color="primary">
                    <CheckCircle />
                  </IconButton>

                  <IconButton onClick={() => cancelLayer()}>
                    <Cancel />
                  </IconButton>
                </Grid>
              </Grid>
            )}
          </Grid>
        )}

        {/* Layers  */}
        {!(selectedGeofence || selectedMicrofence) && searchType?.id === 'GEOFENCES' && (
          <Grid
            container
            style={
              isSome(selectedLayer) && selectedLayer.value?.id !== FRESH_UNKNOWN_LAYER
                ? {
                    display: 'grid',
                    gridTemplateColumns: '90% 10%',
                  }
                : {}
            }
          >
            <FormControl
              fullWidth
              style={{
                width: '98%',
                contentVisibility: createEditLayer ? 'hidden' : 'visible',
              }}
            >
              <InputLabel id="search-group-option">Select a group</InputLabel>
              <Select
                fullWidth
                labelId="search-group-option"
                id="search-group-option"
                disabled={isLoading || reassignedFences.length > 0 || layersHaveChanged}
                value={
                  (layerFilter?.layer === undefined && availableGeofences.length > 0) ||
                  toNullable(selectedLayer)?.id === FRESH_UNKNOWN_LAYER
                    ? 'All Groups'
                    : toNullable(selectedLayer)?.name ?? layerFilter?.layer?.name ?? ''
                }
                label="Select a group"
                onChange={e => {
                  const layer = [...[{ name: 'All Groups', id: 'ALL' }], ...layerIds].find(
                    l => l.name === e.target.value,
                  );
                  if (layer?.id !== 'ALL') {
                    setSelectedLayer(fromNullable(layer?.id));
                  }

                  if (layer?.id.includes(FRESH)) return;
                  setLayerFilter({
                    ...layerFilter,
                    layer,
                  });
                  pageRef.current = 1;
                  paginateGeofences({ ...geofenceFilter, layerId: layer?.id } as GeofenceFilter);
                  if (layer?.id === 'ALL') {
                    setSelectedLayer(none);
                    setSelectedGeofence(undefined);
                    setSelectedMicrofence(undefined);
                    deselectAllFences();
                    setDrawType(none);
                    layerIds?.forEach(layer =>
                      changeVisibility(layer.id, layer.id !== MICROFENCE_LAYER_ID),
                    );
                    setLayerNameInput('');
                  }
                }}
              >
                {[
                  ...[{ name: 'All Groups', id: 'ALL' }],
                  ...layerIds.filter(lyr => lyr.id !== MICROFENCE_LAYER_ID),
                ].map(layer => (
                  <MenuItem key={layer.id} value={layer.name}>
                    <Tooltip title={layer.name}>
                      <Typography
                        style={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 30px)',
                        }}
                      >
                        {layer.name}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {!createEditLayer &&
              isSome(selectedLayer) &&
              selectedLayer.value?.id !== FRESH_UNKNOWN_LAYER && (
                <Grid
                  item
                  container
                  direction="row"
                  style={{
                    marginTop: '-10px',
                  }}
                >
                  <Tooltip title={'EDIT'}>
                    <span>
                      <IconButton
                        color="primary"
                        disabled={isLoading || reassignedFences.length > 0 || layersHaveChanged}
                        onClick={() => {
                          setLayerNameInput(selectedLayer.value.name);
                          setCreateEditLayer('EDIT');
                        }}
                      >
                        <Edit />
                      </IconButton>
                    </span>
                  </Tooltip>

                  <Tooltip title={'DELETE'}>
                    <span>
                      <IconButton
                        color="primary"
                        disabled={isLoading || reassignedFences.length > 0 || layersHaveChanged}
                        onClick={() => {
                          if (isSome(selectedLayer) && selectedLayer.value?.id.includes(FRESH)) {
                            setLayerIds(
                              layerIds.filter(
                                lyr => lyr.id.includes(FRESH) && lyr.name === layerNameInput,
                              ),
                            );
                            setCreateEditLayer(undefined);
                            setLayersHaveChanged(false);
                          } else {
                            setDeleting('LAYER');
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              )}
          </Grid>
        )}

        {/* Filter button and count  */}
        {!createEditLayer &&
          layerFilter?.layer &&
          !(selectedGeofence || selectedMicrofence) &&
          !isLoading && (
            <Grid
              container
              direction="column"
              style={{
                display: 'grid',
                gridTemplateColumns: '50% 50%',
              }}
            >
              <Grid item>
                <Button onClick={() => setShowFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Filter</span>
                  <FilterList />
                </Button>
                <Button onClick={() => setClearFilter(true)}>
                  <span style={{ fontSize: '10px' }}>Clear</span>
                  <Clear />
                </Button>
              </Grid>

              <Grid
                item
                style={{
                  margin: '8px 10px 0px 0px',
                  justifySelf: 'end',
                }}
              >
                {searchType?.id === 'GEOFENCES' && (
                  <Typography>{`${count} ${
                    Number(count) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
                {searchType?.id === 'MICROFENCES' && (
                  <Typography>{`${availableMicrofences.length} ${
                    Number(availableMicrofences.length) === 1 ? 'result' : 'results'
                  }`}</Typography>
                )}
              </Grid>
            </Grid>
          )}

        {mapIsLoading && !searchType && LoadingCircle()}

        {/* Geofences, Microfences  */}
        {!createEditLayer && !(selectedGeofence || selectedMicrofence) && (
          <Grid
            container
            direction={'row'}
            style={{
              marginTop: '10px',
              height: 'fit-content',
              maxHeight: '620px',
              overflowY: 'auto',
            }}
            onScroll={e => {
              const target = e.target as HTMLTextAreaElement;
              if (
                target.scrollTop % target.offsetTop < 20 &&
                !isLoading &&
                searchType?.id === 'GEOFENCES' &&
                count > availableGeofences.length
              ) {
                pageRef.current += 1;
                paginateGeofences({
                  ...geofenceFilter,
                  layerId: layerFilter?.layer?.id,
                  perPage: 50,
                } as GeofenceFilter);
              }
            }}
          >
            {isLoading && LoadingCircle()}

            {/* Geofences */}
            {!isLoading &&
              searchType?.id === 'GEOFENCES' &&
              availableGeofences.map(fence => {
                return (
                  <Accordion id={fence.id} key={fence.id} expanded={false} style={{ width: '98%' }}>
                    <AccordionSummary
                      onClick={() => {
                        if (fence.id.includes(FRESH) && fence.layerId === FRESH_UNKNOWN_LAYER) {
                          setOpenGenericDialog(true);
                          return;
                        }
                        setDisplayGeomobyProperties(
                          Object.entries(fence.geomobyProperties).map(
                            ([property, value], index) => {
                              return {
                                index: index as number,
                                property: property as string,
                                value: value as string,
                              };
                            },
                          ),
                        );
                        setDisplayGeomobyOverrides(fence.geomobyOverrides);
                        fence.selected = true;
                        setSelectedGeofence(fence);
                        setFenceNameInput(fence.name);
                        setReassignedLayerId('');
                        setBufferOffset(undefined);
                        setBufferShape('Circle');
                        getGeofence(fence);
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getFenceIcon(
                        fence.type?.toLowerCase()?.includes('multipolygon') ||
                          fence.points?.type?.toLowerCase()?.includes('multipolygon')
                          ? FenceZone.cleared
                          : fence.zone,
                      )}
                      <Tooltip
                        title={`${fence.name}${
                          !fence.zone || fence.zone === FenceZone.none
                            ? ''
                            : ' (' + fence.zone.toUpperCase() + ')'
                        }`}
                      >
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {fence.name}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}

            {/* Microfences */}
            {!isLoading &&
              searchType?.id === 'MICROFENCES' &&
              availableMicrofences.map(microfence => {
                return (
                  <Accordion
                    id={microfence.id}
                    key={microfence.id}
                    expanded={false}
                    style={{ width: '98%' }}
                  >
                    <AccordionSummary
                      onClick={async () => {
                        const coords =
                          microfence.point?.coordinates ?? microfence.geometry?.getExtent();
                        const extent = transformExtent(
                          [...coords, ...coords],
                          'EPSG:4326',
                          'EPSG:3857',
                        );
                        microfence.selected = true;
                        setSelectedMicrofence(microfence);
                        if (coords) {
                          setExtent(some(extent));
                        }
                        setDisplayGeomobyProperties(
                          Object.entries(microfence.geomobyProperties).map(
                            ([property, value], index) => {
                              return {
                                index: index as number,
                                property: property as string,
                                value: value as string,
                              };
                            },
                          ),
                        );
                        setFenceNameInput(microfence.name);
                        const assetId = microfence.assetId;
                        if (assetId.uuid && assetId.major && assetId.minor) {
                          setMicrofenceIBeaconIdentifier({
                            uuid: String(assetId.uuid),
                            major: Number(assetId.major),
                            minor: Number(assetId.minor),
                          });
                        } else if (assetId.deviceId || assetId.gatewayId || assetId.smartplugId) {
                          setMicrofenceIdInput(
                            assetId.deviceId ?? assetId.gatewayId ?? assetId.smartplugId,
                          );
                        }
                      }}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          width: '90%',
                        },
                      }}
                    >
                      {getMicroFenceIcon(microfence.microfenceType)}
                      <Tooltip title={microfence.name}>
                        <Typography
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                        >
                          {microfence.name}
                        </Typography>
                      </Tooltip>
                    </AccordionSummary>
                  </Accordion>
                );
              })}
          </Grid>
        )}

        {/* Selected Geofence  */}
        {!createEditLayer && !createEditFence && selectedGeofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Box
              style={{
                marginTop: '5px',
              }}
              color="primary.main"
            >
              {getFenceIcon(isMultipolygon ? FenceZone.cleared : selectedGeofence.zone)}
            </Box>

            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginBottom:
                  (!isMultipolygon && selectedGeofence.zone === FenceZone.none) || isLine
                    ? '0px'
                    : '10px',
                marginTop:
                  (!isMultipolygon && selectedGeofence.zone === FenceZone.none) || isLine
                    ? '0px'
                    : '-37px',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={'NAVIGATE TO'}>
                <IconButton color="primary" onClick={() => setNavigateTo(selectedGeofence.id)}>
                  <NearMe />
                </IconButton>
              </Tooltip>

              <Tooltip title={'DOWNLOAD'}>
                <span>
                  <IconButton
                    id="download-geojson"
                    color="primary"
                    disabled={
                      selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                      selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                    }
                    style={{
                      justifySelf: 'end',
                    }}
                    onClick={() => downloadGeoJSON()}
                  >
                    <Download />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title={'EDIT'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setSelectedLayer(fromNullable(selectedGeofence.layerId));
                    if (!layerFilter) {
                      resetlayer();
                    }
                    setFenceNameInput(selectedGeofence.name);
                    setBufferOffset(undefined);
                    setBufferShape('Circle');
                    setCreateEditFence('EDIT');
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              {selectedGeofence.zone !== FenceZone.buffer && (
                <Tooltip title={'DELETE'}>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setSelectedLayer(fromNullable(selectedGeofence.layerId));
                      if (!layerFilter) {
                        resetlayer();
                      }
                      setDeleting('GEOFENCE');
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>

            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip title={selectedGeofence.name} style={lineLimitStyle}>
                <Typography variant="h5">{selectedGeofence.name}</Typography>
              </Tooltip>
            </Grid>

            {selectedGeofence.layerId !== FRESH_UNKNOWN_LAYER && (
              <Grid
                container
                direction="column"
                style={{
                  marginBottom: '10px',
                  width: '90%',
                  alignContent: 'start',
                }}
              >
                <Button
                  style={{
                    marginLeft: '-7px',
                    color: '#fff',
                  }}
                  onClick={() => {
                    if (!selectedGeofence) return;
                    setSelectedGeofence(undefined);
                    setFenceNameInput('');
                    setSelectedMicrofence(undefined);
                    setSearchType({ id: 'GEOFENCES', value: 'Geofences' });
                    setGeofenceFilter({ perPage: 50 });
                    pageRef.current = 1;
                    resetlayer();
                    deselectAllFences();
                  }}
                >
                  {layerIds.find(lyr => lyr.id === selectedGeofence.layerId)?.name ?? ''}
                </Button>
              </Grid>
            )}

            {selectedGeofence.layerId === FRESH_UNKNOWN_LAYER && (
              <Grid
                container
                direction="column"
                style={{
                  marginBottom: '10px',
                  color: '#924439',
                }}
              >
                No group assigned
              </Grid>
            )}

            {displayGeomobyProperties && (
              <>
                <Tooltip title={'Geofence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Geofence Properties</Typography>
                </Tooltip>
                <PropertiesTable rows={displayGeomobyProperties}></PropertiesTable>
              </>
            )}
            {displayGeomobyOverrides && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Override Rules'}>
                  <Typography style={{ color: '#4CB8C4' }}>Override Rules</Typography>
                </Tooltip>
                <OverridesTable rows={displayGeomobyOverrides}></OverridesTable>
              </Grid>
            )}
          </Paper>
        )}

        {/* Create/Edit Geofence */}
        {createEditFence && selectedGeofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid
              container
              direction="row"
              sx={{
                '& .MuiTypography-root': {
                  marginLeft: '0px',
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  ...truncationStyle,
                },
                '& .MuiStack-root': {
                  width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                  justifyContent: 'start',
                },
                marginTop: '10px',
              }}
            >
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="rename">Name</InputLabel>
                <OutlinedInput
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={fenceNameRef}
                  placeholder="Geofence 1"
                  disabled={
                    selectedGeofence.zone === FenceZone.buffer ||
                    selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                  }
                  value={fenceNameInput}
                  onChange={async ({ target: { value } }) => {
                    setFenceNameInput(value);
                    selectedGeofence.name = value;
                    debounceUpdateGeofence(
                      selectedGeofence.id,
                      selectedGeofence.name,
                      fenceType,
                      selectedGeofence.layerId,
                      selectedGeofence.zone,
                    );
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      saveAllChanges();
                    }
                  }}
                />
              </FormControl>
            </Grid>

            {isPolygon && selectedGeofence.zone !== FenceZone.buffer && (
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                }}
              >
                <InputLabel id="zone-type-option">Zone</InputLabel>
                <Select
                  fullWidth
                  labelId="zone-type-action"
                  id="zone-type-dropdown"
                  disabled={
                    selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                  }
                  value={
                    FenceZoneTypes.find(z => String(z.id) === selectedGeofence.zone)?.value ?? ''
                  }
                  label="Zone"
                  style={{
                    height: '60px',
                  }}
                  onChange={async e => {
                    const zoneType = FenceZoneTypes.find(
                      z => z.value === (e.target.value as FenceZoneTypeValue),
                    );
                    if (!zoneType) return;
                    if (zoneType.id === FenceZone.breach) {
                      await setAsBreachZone(
                        selectedGeofence,
                        undefined,
                        bufferOffset ?? DEFAULT_BUFFER_METERS,
                        bufferShape,
                      );
                    } else {
                      setBufferOffset(undefined);
                      if (zoneType.id === FenceZone.buffer) {
                        await setAsBufferZone(selectedGeofence);
                      } else if (zoneType.id === FenceZone.none) {
                        if (selectedGeofence.zone === FenceZone.breach) {
                          const bufferId = findBufferFromBreach(selectedGeofence?.id);
                          if (!bufferId) return;
                          await unsetAsBreachZone(selectedGeofence, bufferId);
                        } else if (selectedGeofence.zone === FenceZone.buffer) {
                          await unsetZone(selectedGeofence);
                        }
                      } else {
                        await setZone(selectedGeofence, zoneType.id);
                      }
                    }

                    setSelectedGeofence({
                      ...selectedGeofence,
                      zone: zoneType.id as FenceZone,
                    });
                    setLayersHaveChanged(true);
                  }}
                >
                  {getZonesSelection().map(zone => (
                    <MenuItem key={zone.id} value={zone.value}>
                      <Tooltip title={zone.value}>
                        <Typography
                          sx={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: 'calc(100% - 50px)',
                            '& .MuiSvgIcon-root': {
                              marginBottom: '-7px',
                            },
                          }}
                        >
                          {getFenceIcon(zone.id)}
                          <span
                            style={{
                              padding: '10px',
                            }}
                          ></span>{' '}
                          {zone.value}
                        </Typography>
                      </Tooltip>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedGeofence?.zone === FenceZone.breach && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Set Buffer Zone'}>
                  <Typography style={{ color: '#4CB8C4' }}>Set Buffer Zone</Typography>
                </Tooltip>

                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                    marginBottom: '20px',
                  }}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={bufferShape}
                    sx={{
                      '& .MuiButtonBase-root': {
                        width: '50%',
                      },
                    }}
                    exclusive
                    onChange={async e => {
                      const newShape = (e.target as EventTarget & HTMLInputElement)
                        .value as BufferShapeType;
                      setBufferShape(newShape);
                      setIsLoading(true);
                      const bufferId = findBufferFromBreach(selectedGeofence?.id);
                      if (bufferId) {
                        await setAsBreachZone(
                          selectedGeofence,
                          bufferId,
                          bufferOffset ?? DEFAULT_BUFFER_METERS,
                          newShape,
                        );
                      }
                      setLayersHaveChanged(true);
                      setIsLoading(false);
                    }}
                    aria-label="Platform"
                  >
                    <ToggleButton value="Circle">Circle</ToggleButton>
                    <ToggleButton value="Scaled">Scaled</ToggleButton>
                  </ToggleButtonGroup>

                  <FormControl>
                    <InputLabel id="offset">Offset</InputLabel>
                    <OutlinedInput
                      id="offset"
                      label="Offset"
                      key={'offset'}
                      name={'offset'}
                      inputRef={bufferOffsetRef}
                      value={bufferOffset ?? DEFAULT_BUFFER_METERS}
                      onChange={async ({ target: { value } }) => {
                        if (!isNaN(Number(value))) {
                          setBufferOffset(Number(value));
                          const bufferId = findBufferFromBreach(selectedGeofence.id);
                          if (!bufferId) return;
                          await setAsBreachZone(
                            selectedGeofence,
                            bufferId,
                            Number(value),
                            bufferShape,
                          );
                          setLayersHaveChanged(true);
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {/* Geofence Properties */}
            {selectedGeofence?.geomobyProperties && (
              <GeomobyProperties
                selectedGeofence={selectedGeofence}
                displayGeomobyProperties={displayGeomobyProperties}
                setDisplayGeomobyProperties={setDisplayGeomobyProperties}
                properties={properties}
                saveAllChanges={saveAllChanges}
                updateGeomobyProperties={updateGeomobyProperties}
              />
            )}

            {/* Geofence Overrides */}
            {selectedGeofence?.geomobyOverrides && (
              <GeomobyOverrides
                selectedGeofence={selectedGeofence}
                displayGeomobyOverrides={displayGeomobyOverrides.map(
                  (override: GeomobyOverride, index: number) => {
                    return {
                      ...override,
                      index,
                    };
                  },
                )}
                setDisplayGeomobyOverrides={setDisplayGeomobyOverrides}
                properties={properties}
                saveAllChanges={saveAllChanges}
                updateGeomobyOverrides={updateGeomobyOverrides}
              />
            )}

            {/* Reassign to another layer */}
            {selectedGeofence.zone !== FenceZone.buffer &&
              ((!selectedGeofence.id.includes(FRESH) &&
                selectedGeofence?.layerId !== FRESH_UNKNOWN_LAYER) ||
                (selectedGeofence.id.includes(FRESH) &&
                  (selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                    selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER))) && (
                <Grid item xs={12}>
                  <FormControl
                    fullWidth
                    style={{
                      margin: '50px 0px 10px 0px',
                    }}
                  >
                    <InputLabel id="reassign-layer">{`${
                      selectedGeofence.layerId === FRESH_UNKNOWN_LAYER
                        ? 'Assign to a group'
                        : 'Reassign to another group'
                    }`}</InputLabel>
                    <Select
                      id="reassign-layer"
                      labelId="reassign-layer"
                      label={`${
                        selectedGeofence.layerId === FRESH_UNKNOWN_LAYER
                          ? 'Assign to a group'
                          : 'Reassign to another group'
                      }`}
                      fullWidth
                      onChange={async e => {
                        if (
                          selectedGeofence.layerId === FRESH_UNKNOWN_LAYER ||
                          selectedGeofence.previousLayer === FRESH_UNKNOWN_LAYER
                        ) {
                          setFreshGeofences([
                            {
                              ...selectedGeofence,
                              layerId: e.target.value,
                              previousLayer: FRESH_UNKNOWN_LAYER,
                            },
                          ]);
                          const filter = { ...geofenceFilter, layerId: e.target.value };
                          await paginateGeofences(filter as GeofenceFilter);
                          setSelectedGeofence(undefined);
                          setSelectedLayer(fromNullable(e.target.value));
                          moveUnknownFenceToExistingLayer(e.target.value);
                          setGeofenceFilter(filter);
                        } else {
                          setReassignedFences([
                            ...reassignedFences.filter(f => f.id !== selectedGeofence.id),
                            {
                              id: selectedGeofence.id,
                              newLayerId: e.target.value,
                              type: isPolygon
                                ? 'polygon'
                                : isMultipolygon
                                ? 'multipolygon'
                                : 'line',
                            },
                          ]);
                          setReassignedLayerId(e.target.value);
                        }
                        setLayersHaveChanged(true);
                      }}
                      value={reassignedLayerId ?? ''}
                    >
                      <MenuItem value="none" />
                      {layerIds
                        ?.filter(l => l.id !== MICROFENCE_LAYER_ID && l.id !== FRESH_UNKNOWN_LAYER)
                        .sort((a, b) => a.name?.localeCompare(b.name))
                        .map(layer => (
                          <MenuItem key={layer.id} value={layer.id}>
                            <Tooltip title={layer.name}>
                              <Typography
                                style={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  width: 'calc(100% - 30px)',
                                }}
                              >
                                {layer.name}
                              </Typography>
                            </Tooltip>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
          </Paper>
        )}

        {/* Selected Microfence */}
        {!createEditLayer && !createEditFence && selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Box
              style={{
                marginTop: '5px',
              }}
              color="primary.main"
            >
              {getMicroFenceIcon(selectedMicrofence.microfenceType)}
            </Box>

            <Grid
              container
              direction="row"
              style={{
                width: '100%',
                marginTop: '-44px',
                justifyContent: 'flex-end',
              }}
            >
              <Tooltip title={'NAVIGATE TO'}>
                <IconButton color="primary" onClick={() => setNavigateTo(selectedMicrofence.id)}>
                  <NearMe />
                </IconButton>
              </Tooltip>

              <Tooltip title={'EDIT'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (!layerFilter) {
                      resetlayer();
                    }
                    microfenceSort();
                    setFenceNameInput(selectedMicrofence.name);
                    const assetId = selectedMicrofence.assetId;
                    if (selectedMicrofence.microfenceType === 'beacon') {
                      setMicrofenceIBeaconIdentifier({
                        uuid: String(assetId?.uuid),
                        major: Number(assetId?.major),
                        minor: Number(assetId?.minor),
                      });
                    } else {
                      setMicrofenceIdInput(
                        assetId?.deviceId ?? assetId.gatewayId ?? assetId?.smartplugId,
                      );
                    }
                    setCreateEditFence('EDIT');
                  }}
                >
                  <Edit />
                </IconButton>
              </Tooltip>

              <Tooltip title={'DELETE'}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    if (!layerFilter) {
                      resetlayer();
                    }
                    setDeleting('MICROFENCE');
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="row"
              spacing={1}
              marginLeft={0}
              justifyContent="left"
              alignItems="center"
            >
              <Tooltip
                title={selectedMicrofence.name}
                style={{
                  ...lineLimitStyle,
                  marginTop: '5px',
                }}
              >
                <Typography variant="h5">{selectedMicrofence.name}</Typography>
              </Tooltip>
            </Grid>

            <Grid
              container
              direction="column"
              style={{
                marginBottom: '10px',
                width: '70px',
              }}
            >
              <Button
                style={{
                  marginLeft: '-7px',
                  color: '#fff',
                }}
                onClick={() => {
                  setSearchType({ id: 'MICROFENCES', value: 'Microfences' });
                  setSelectedMicrofence(undefined);
                  setGeofenceFilter({ perPage: 50 });
                  pageRef.current = 1;
                  resetlayer();
                  deselectAllFences();
                }}
              >
                {MICROFENCE_LAYER_LABEL}
              </Button>
            </Grid>

            <Grid container direction={'row'} justifyContent={'center'}>
              {selectedMicrofence.microfenceType === 'beacon' && (
                <>
                  <Grid container direction={'row'}>
                    <Tooltip title={'UUID'}>
                      <Typography
                        style={{
                          color: '#4CB8C4',
                          paddingRight: '5px',
                          width: 'fit-content',
                        }}
                      >
                        {`UUID: `}
                      </Typography>
                    </Tooltip>
                    <Tooltip
                      title={
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.uuid
                      }
                    >
                      <Typography>
                        {
                          (
                            selectedMicrofence.assetId as {
                              uuid: string;
                              major: string;
                              minor: string;
                            }
                          )?.uuid
                        }
                      </Typography>
                    </Tooltip>
                  </Grid>
                </>
              )}
              {(selectedMicrofence.microfenceType === 'gateway' ||
                selectedMicrofence.microfenceType === 'smartplug') && (
                <>
                  {displayAssetId(
                    (selectedMicrofence.assetId as { gatewayId: string }).gatewayId ||
                      (selectedMicrofence.assetId as { smartplugId: string }).smartplugId,
                    EntityType.Gateway,
                  )}
                </>
              )}
              {selectedMicrofence.microfenceType === 'device' && (
                <>
                  {displayAssetId(
                    (selectedMicrofence.assetId as { deviceId: string }).deviceId,
                    EntityType.Device,
                  )}
                </>
              )}

              {selectedMicrofence.microfenceType === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Major'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Major:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        ?.major
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.major
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}

              {selectedMicrofence.microfenceType === 'beacon' && (
                <Grid item container direction={'row'} xs={12} fontSize="90%">
                  <Tooltip title={'Minor'}>
                    <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>
                      Minor:
                    </Typography>
                  </Tooltip>
                  <Tooltip
                    title={
                      (selectedMicrofence.assetId as { uuid: string; major: string; minor: string })
                        ?.minor
                    }
                  >
                    <Typography>
                      {
                        (
                          selectedMicrofence.assetId as {
                            uuid: string;
                            major: string;
                            minor: string;
                          }
                        )?.minor
                      }
                    </Typography>
                  </Tooltip>
                </Grid>
              )}
            </Grid>

            <Grid item container direction={'row'} xs={12} fontSize="90%">
              <Tooltip title={'Zone'}>
                <Typography style={{ color: '#4CB8C4', paddingRight: '5px' }}>Zone:</Typography>
              </Tooltip>
              <Tooltip
                title={
                  MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'
                }
              >
                <Typography>
                  {MicrofenceZoneTypes.find(
                    z => z.id === (selectedMicrofence?.zone as MicrofenceZone),
                  )?.value ?? 'No Zone'}
                </Typography>
              </Tooltip>
            </Grid>

            {displayGeomobyProperties && (
              <>
                <Tooltip title={'Microfence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Microfence Properties</Typography>
                </Tooltip>
                <PropertiesTable rows={displayGeomobyProperties}></PropertiesTable>
              </>
            )}
          </Paper>
        )}

        {/* Create/Edit Microfence */}
        {createEditFence && selectedMicrofence && (
          <Paper
            variant="outlined"
            style={{
              padding: '10px',
              height: 'fit-content',
            }}
          >
            <Grid>
              {/* Name */}
              <FormControl
                style={{
                  width: '100%',
                  marginBottom: '15px',
                  marginTop: '10px',
                }}
              >
                <InputLabel id="rename">Name</InputLabel>
                <OutlinedInput
                  id="rename"
                  label="Name"
                  key={'rename'}
                  name={'rename'}
                  inputRef={fenceNameRef}
                  placeholder="Geofence 1"
                  value={fenceNameInput}
                  onChange={async ({ target: { value } }) => {
                    setFenceNameInput(value);
                    setSelectedMicrofence({
                      ...selectedMicrofence,
                      name: value,
                    });
                    debounceUpdateMicrofence(
                      selectedMicrofence.id,
                      value,
                      selectedMicrofence.zone,
                      selectedMicrofence.assetId,
                    );
                  }}
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      saveAllChanges();
                    }
                  }}
                />
              </FormControl>

              {/* New Microfence gateway ID, Device ID */}
              {selectedMicrofence.microfenceType !== 'beacon' && (
                <FormControl
                  style={{
                    width: '75.5%',
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="microfence-label">
                    {selectedMicrofence.microfenceType === 'gateway' ? 'Gateway ID' : 'Device ID'}
                  </InputLabel>
                  <OutlinedInput
                    id="microfence-id"
                    label={
                      selectedMicrofence.microfenceType === 'gateway' ? 'Gateway ID' : 'Device ID'
                    }
                    key={'microfence'}
                    name={'microfence'}
                    inputRef={microfenceIdRef}
                    placeholder={
                      selectedMicrofence.microfenceType === 'gateway' ? 'Gateway ID' : 'Device ID'
                    }
                    value={microfenceIdInput}
                    onChange={async (e: { target: { value: string } }) => {
                      await updateAssetId(e.target.value);
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        saveAllChanges();
                      }
                    }}
                  />
                </FormControl>
              )}

              {/* New Microfence Beacon UUID */}
              {selectedMicrofence.microfenceType === 'beacon' && (
                <FormControl
                  style={{
                    width: '100%',
                    marginBottom: '15px',
                  }}
                >
                  <InputLabel id="microfence-uuid">UUID</InputLabel>
                  <OutlinedInput
                    id="microfence-uuid-id"
                    label={'UUID'}
                    key={'microfence-uuid'}
                    name={'microfence-uuid'}
                    inputRef={microfenceUUIDRef}
                    placeholder=""
                    value={microfenceIBeaconIdentifier.uuid}
                    onChange={async ({ target: { value } }) => {
                      setMicrofenceIBeaconIdentifier({
                        ...microfenceIBeaconIdentifier,
                        uuid: value,
                      });
                      const assetId = {
                        uuid: value,
                        major: String(microfenceIBeaconIdentifier.major),
                        minor: String(microfenceIBeaconIdentifier.minor),
                      };

                      setSelectedMicrofence({
                        ...selectedMicrofence,
                        assetId,
                      });
                      debounceUpdateMicrofence(
                        selectedMicrofence.id,
                        selectedMicrofence.name,
                        selectedMicrofence.zone,
                        assetId,
                      );
                    }}
                    onKeyPress={event => {
                      if (event.key === 'Enter') {
                        saveAllChanges();
                      }
                    }}
                  />
                </FormControl>
              )}

              {selectedMicrofence.microfenceType === 'beacon' && (
                <Grid
                  container
                  direction="row"
                  style={{
                    marginTop: '10px',
                    width: '100%',
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                  }}
                >
                  {/* Major */}
                  <FormControl
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <InputLabel id="major-label">Major</InputLabel>
                    <OutlinedInput
                      id="major-id"
                      label={'Major'}
                      key={'major'}
                      name={'major'}
                      type={'number'}
                      inputRef={microfenceMajorRef}
                      value={microfenceIBeaconIdentifier.major}
                      onChange={async ({ target: { value } }) => {
                        const updatedMajor = Number(value) < 0 ? 0 : Number(value);
                        setMicrofenceIBeaconIdentifier({
                          ...microfenceIBeaconIdentifier,
                          major: updatedMajor,
                        });
                        const assetId = {
                          uuid: microfenceIBeaconIdentifier.uuid,
                          major: String(updatedMajor),
                          minor: String(microfenceIBeaconIdentifier.minor),
                        };

                        setSelectedMicrofence({
                          ...selectedMicrofence,
                          assetId,
                        });
                        debounceUpdateMicrofence(
                          selectedMicrofence.id,
                          selectedMicrofence.name,
                          selectedMicrofence.zone,
                          assetId,
                        );
                      }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          saveAllChanges();
                        }
                      }}
                    />
                  </FormControl>

                  {/* Minor */}
                  <FormControl
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    <InputLabel id="minor-label">Minor</InputLabel>
                    <OutlinedInput
                      id="minor-id"
                      label={'Minor'}
                      key={'minor'}
                      name={'minor'}
                      type={'number'}
                      inputRef={microfenceMinorRef}
                      value={microfenceIBeaconIdentifier.minor}
                      onChange={async ({ target: { value } }) => {
                        const updatedMinor = Number(value) < 0 ? 0 : Number(value);
                        setMicrofenceIBeaconIdentifier({
                          ...microfenceIBeaconIdentifier,
                          minor: updatedMinor,
                        });
                        const assetId = {
                          uuid: microfenceIBeaconIdentifier.uuid,
                          major: String(microfenceIBeaconIdentifier.major),
                          minor: String(updatedMinor),
                        };

                        setSelectedMicrofence({
                          ...selectedMicrofence,
                          assetId,
                        });
                        debounceUpdateMicrofence(
                          selectedMicrofence.id,
                          selectedMicrofence.name,
                          selectedMicrofence.zone,
                          assetId,
                        );
                      }}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          saveAllChanges();
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              )}
            </Grid>

            {/* Microfence Zone */}
            <FormControl
              style={{
                marginTop: '10px',
                width: '100%',
                marginBottom: '15px',
              }}
            >
              <InputLabel id="zone-type-option">Zone</InputLabel>
              <Select
                fullWidth
                labelId="zone-type-action"
                id="zone-type-dropdown"
                value={
                  MicrofenceZoneTypes.find(z => String(z.id) === selectedMicrofence.zone)?.value ??
                  'No Zone'
                }
                label="Zone"
                style={{
                  height: '60px',
                }}
                onChange={async e => {
                  const zoneType = MicrofenceZoneTypes.find(
                    z => z.value === (e.target.value as MicrofenceZoneTypeValue),
                  );
                  if (!zoneType) return;
                  const zone = zoneType.id as MicrofenceZone;
                  setMicrofenceZoneInput(zone);
                  setSelectedMicrofence({
                    ...selectedMicrofence,
                    zone,
                  });
                  debounceUpdateMicrofence(
                    selectedMicrofence.id,
                    selectedMicrofence.name,
                    zone,
                    selectedMicrofence.assetId,
                  );
                }}
              >
                {MicrofenceZoneTypes.map(zone => (
                  <MenuItem key={zone.id} value={zone.value}>
                    <Tooltip title={zone.value}>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          width: 'calc(100% - 50px)',
                          '& .MuiSvgIcon-root': {
                            marginBottom: '-7px',
                          },
                        }}
                      >
                        {zone.value}
                      </Typography>
                    </Tooltip>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* Microfence Properties */}
            {displayGeomobyProperties && (
              <Grid
                style={{
                  marginTop: '10px',
                }}
              >
                <Tooltip title={'Microfence Properties'}>
                  <Typography style={{ color: '#4CB8C4' }}>Microfence Properties</Typography>
                </Tooltip>

                <Grid
                  container
                  style={{
                    display: 'grid',
                    gap: '2%',
                    gridTemplateColumns: '49% 49%',
                  }}
                >
                  <Tooltip title={'Property'}>
                    <Typography>Property</Typography>
                  </Tooltip>
                  <Tooltip title={'Value'}>
                    <Typography>Value</Typography>
                  </Tooltip>
                </Grid>

                {displayGeomobyProperties
                  .sort((a, b) => {
                    return String(a.index).localeCompare(String(b.index));
                  })
                  .map(prop => {
                    return (
                      <Grid
                        container
                        key={prop.index}
                        sx={{
                          display: 'grid',
                          gap: '2%',
                          gridTemplateColumns: '45% 45% 5%',
                          '& .MuiInputBase-root': {
                            height: '35px',
                          },
                        }}
                      >
                        <FormControl>
                          <InputContainer
                            id={'prop-property-' + prop.index}
                            label=""
                            key={'prop-property-' + prop.index}
                            name={'prop-property' + prop.index}
                            value={prop.property}
                            disableInput={true}
                            onChange={(e: { target: { value: string } }) => {
                              setDisplayGeomobyProperties([
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  property: e.target.value,
                                  value: prop.value,
                                },
                              ]);
                            }}
                            placeholder=""
                          />
                        </FormControl>

                        <FormControl>
                          <InputContainer
                            id={'prop-value-' + prop.index}
                            label=""
                            key={'prop-value-' + prop.index}
                            name={'prop-value-' + prop.index}
                            type={'number'}
                            value={prop.value}
                            onChange={async (e: { target: { value: string } }) => {
                              const updatedProperties: Record<string, string> = {};
                              const updatedDisplayGeomobyProperties = [
                                ...displayGeomobyProperties.filter(
                                  displayed => displayed.index !== prop.index,
                                ),
                                {
                                  index: prop.index,
                                  property: prop.property,
                                  value: e.target.value,
                                },
                              ];
                              updatedDisplayGeomobyProperties.map(prop => {
                                updatedProperties[prop.property] = prop.value;
                              });
                              await updateGeomobyProperties(updatedProperties);
                              setDisplayGeomobyProperties(updatedDisplayGeomobyProperties);
                            }}
                            onKeyPress={(event: { key: string }) => {
                              if (event.key === 'Enter') {
                                saveAllChanges();
                              }
                            }}
                            placeholder=""
                          />
                        </FormControl>
                      </Grid>
                    );
                  })}
              </Grid>
            )}
          </Paper>
        )}
      </Grid>

      {/* Location */}
      {searchType?.id === 'LOCATIONS' && layerIds && (
        <>
          <div
            style={{
              width: '100px',
              alignSelf: 'start',
              margin: '-20px 0px -20px 20px',
            }}
          >
            <Tooltip title={'Search'}>
              <Typography variant="h5">Search</Typography>
            </Tooltip>
          </div>
          <div
            style={{
              margin: '20px 0px 0px 20px',
            }}
          >
            <LocationSearch
              isGeofenceEditor={false}
              setLocationSearchData={setLocationSearchData}
              currentCenter={currentCenter}
              setLocationDisplay={setLocationDisplay}
            ></LocationSearch>
          </div>

          {locationDisplay && (
            <Paper
              variant="outlined"
              style={{
                padding: '10px',
                height: 'fit-content',
                width: 'fit-content',
                margin: '20px',
              }}
            >
              <Grid
                container
                direction="row"
                sx={{
                  '& .MuiTypography-root': {
                    marginLeft: '0px',
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    ...truncationStyle,
                  },
                  '& .MuiStack-root': {
                    width: isMobile || window.innerWidth < 900 ? '78vw' : '22vw',
                    justifyContent: 'start',
                  },
                }}
              >
                <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                  <Box color="primary.main">
                    <LocationOn />
                  </Box>
                  <Tooltip title={locationDisplay.label}>
                    <Typography variant="h5">{locationDisplay.label}</Typography>
                  </Tooltip>
                </Stack>
              </Grid>
              <Grid
                style={{
                  marginLeft: '32px',
                }}
              >
                <Tooltip title={locationDisplay.coordinates}>
                  <Typography
                    style={{
                      marginLeft: '-7px',
                      color: '#fff',
                    }}
                  >
                    {`${locationDisplay.coordinates[0]}, ${locationDisplay.coordinates[1]}`}
                  </Typography>
                </Tooltip>
              </Grid>
            </Paper>
          )}
        </>
      )}

      {/* Save */}
      {(isSome(selectedLayer) || selectedGeofence || selectedMicrofence || createEditLayer) &&
        searchType?.id !== 'LOCATIONS' &&
        !showFilter && (
          <Grid
            container
            style={{
              display: 'grid',
              gridTemplateColumns: '50% 50%',
              gap: '2%',
              marginLeft: '20px',
              width: '88%',
            }}
          >
            <Button
              variant="contained"
              color="secondary"
              disabled={!layersHaveChanged || isLoading}
              onClick={() => {
                if (reassignedFences.length === 0) {
                  saveAllChanges();
                } else if (reassignedFences.length > 0) {
                  setReassigningToLayer(true);
                }
              }}
            >
              {toNullable(selectedLayer)?.id === MICROFENCE_LAYER_ID
                ? 'Save microfence changes'
                : 'Save group changes'}
            </Button>
            <Button
              variant="outlined"
              disabled={!layersHaveChanged || isLoading}
              onClick={() => cancelAllChanges()}
            >
              Cancel
            </Button>
          </Grid>
        )}

      {/* Dirty */}
      <Dialog
        open={dirtySave.isDirty}
        onClose={() => setDirtySave({ isDirty: false, issue: null })}
      >
        <DialogTitle>{dirtySave.issue}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              if (
                selectedGeofence?.layerId === FRESH_UNKNOWN_LAYER &&
                reassignedFences.length === 0
              ) {
                setFenceNameInput(selectedGeofence.name);
                setBufferOffset(undefined);
                setBufferShape('Circle');
                setCreateEditFence('EDIT');
              }
              setDirtySave({ isDirty: false, issue: null });
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Layer */}
      <Dialog open={deleting === 'LAYER'} onClose={() => setDeleting(undefined)}>
        <DialogTitle>Are you sure you want to delete this group?</DialogTitle>
        <DialogContent>
          <p>All associated {getFenceLabel('OUTDOOR')}s will also be deleted.</p>
          <p>This action is irreversible.</p>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              await deleteLayer();
              setDeleting(undefined);
              const layer = { name: 'All Groups', id: 'ALL' };
              setLayerFilter({
                ...layerFilter,
                layer,
              });
              setSelectedLayer(fromNullable(layer.id));
              pageRef.current = 1;
              setLayerNameInput('');
              paginateGeofences({ ...geofenceFilter, layerId: layer.id } as GeofenceFilter);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setDeleting(undefined);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Deleting Fence */}
      <Dialog
        open={deleting === 'GEOFENCE' || deleting === 'MICROFENCE'}
        onClose={() => setDeleting(undefined)}
      >
        <DialogTitle>Are you sure you want to delete this {deleting?.toLowerCase()}?</DialogTitle>
        <DialogActions>
          <Button
            onClick={async () => {
              if (selectedGeofence?.zone === FenceZone.breach) {
                const buffer = availableGeofences.find(
                  buffer => buffer.parentId === selectedGeofence?.id,
                );
                if (buffer) {
                  deletedFenceIdsRef.current.push(buffer?.id);
                }
                deletedFenceIdsRef.current.push(selectedGeofence?.id);
                if (buffer) {
                  await removeBufferZone(buffer);
                }
                await deleteFence(selectedGeofence, FenceGeometryType.Polygon);
              } else {
                deletedFenceIdsRef.current.push(selectedGeofence?.id ?? selectedMicrofence?.id);
                if (selectedGeofence) {
                  await deleteFence(selectedGeofence, fenceType);
                }
                if (selectedMicrofence) {
                  await deleteFence(selectedMicrofence, fenceType);
                }
              }
              setLayersHaveChanged(true);
              setDeleting(undefined);
              goBack();
            }}
          >
            Yes
          </Button>
          <Button color="secondary" onClick={() => setDeleting(undefined)}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Duplicate Layer */}
      <Dialog open={layerConflict} onClose={() => setLayerConflict(false)}>
        <DialogTitle>A Group with this name already exists. Please choose another one.</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setLayerConflict(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      {/* Reassigning Fences */}
      <Dialog open={reassigningToLayer} onClose={() => setReassigningToLayer(false)}>
        <DialogTitle>{`Any unsaved data for ${
          reassignedFences.length > 1 ? 'these geofences' : 'this geofence'
        } will be lost`}</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button onClick={() => saveAllChanges()}>OK</Button>
          <Button
            onClick={() => {
              setReassigningToLayer(false);
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Generic Save Changes Dialog */}
      <Dialog open={openGenericDialog} onClose={() => setOpenGenericDialog(false)}>
        <DialogTitle>Please save group changes before editing this geofence</DialogTitle>
        <DialogActions style={{ alignSelf: 'center' }}>
          <Button
            onClick={() => {
              setOpenGenericDialog(false);
            }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
