import { Dispatch } from 'react';
import { GeofenceFilter, LayerFilter, MicrofenceFilter, SearchType } from '../../types';
import { GridRowData } from '@material-ui/data-grid';
import { LiveMapGeofenceFilter } from '../../LiveMapGeofenceFilter';
import { LiveMapMicrofenceFilter } from '../../LiveMapMicrofenceFilter';

export const GeofenceEditorFilter = ({
  searchType,
  layerFilter,
  setLayerFilter,
  geofenceFilter,
  setGeofenceFilter,
  microfenceFilter,
  setMicrofenceFilter,
  selectedMicrofence,
  clearFilter,
  setClearFilter,
  showFilter,
  setShowFilter,
  setRefreshSearch,
}: {
  searchType: SearchType | undefined;
  layerFilter: LayerFilter | undefined;
  setLayerFilter: Dispatch<LayerFilter | undefined>;
  geofenceFilter: GeofenceFilter;
  setGeofenceFilter: Dispatch<GeofenceFilter>;
  microfenceFilter: MicrofenceFilter | undefined;
  setMicrofenceFilter: Dispatch<MicrofenceFilter | undefined>;
  selectedMicrofence: GridRowData | undefined;
  clearFilter: boolean;
  setClearFilter: Dispatch<boolean>;
  showFilter: boolean;
  setShowFilter: Dispatch<boolean>;
  setRefreshSearch: Dispatch<boolean>;
}) => {
  return (
    <div
      style={{
        margin: '20px',
      }}
    >
      {searchType?.id === 'GEOFENCES' && (
        <LiveMapGeofenceFilter
          layerFilter={layerFilter}
          setLayerFilter={setLayerFilter}
          geofenceFilter={geofenceFilter}
          setGeofenceFilter={setGeofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapGeofenceFilter>
      )}

      {searchType?.id === 'MICROFENCES' && (
        <LiveMapMicrofenceFilter
          microfenceFilter={microfenceFilter}
          setMicrofenceFilter={setMicrofenceFilter}
          clearFilter={clearFilter}
          setClearFilter={setClearFilter}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          setRefreshSearch={setRefreshSearch}
        ></LiveMapMicrofenceFilter>
      )}
    </div>
  );
};
