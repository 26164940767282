import { atomFromEnv } from '../util/atomFromEnv';

export const LTP_API = atomFromEnv('REACT_APP_LTP_API', `${location.origin}/api`);
export const LTP_WS = atomFromEnv(
  'REACT_APP_LTP_WS',
  `${location.origin.replace('http', 'ws')}/api`,
);
export const ANALYTICS_API = atomFromEnv(
  'REACT_APP_ANALYTICS_API',
  `${location.origin.replace('live', 'analytics')}/api`,
);

export const AUTHN_URL = atomFromEnv(
  'REACT_APP_AUTHN_URL',
  location.origin
    .replace('live.geomoby', 'authn.service.geomoby')
    .replace('live-beta.geomoby', 'authn.service.geomoby')
    .replace('live', 'authn')
    .replace('3000', '8009'),
);
export const PERSISTOR_URL = atomFromEnv(
  'REACT_APP_PERSISTOR_URL',
  location.origin
    .replace('live.geomoby', 'persistor.service.geomoby')
    .replace('live-beta.geomoby', 'persistor.service.geomoby')
    .replace('live', 'persistor')
    .replace('3000', '8011'),
);
export const TRIGGERS_URL = atomFromEnv(
  'REACT_APP_TRIGGERS_URL',
  location.origin
    .replace('live.geomoby', 'triggers.service.geomoby')
    .replace('live-beta.geomoby', 'triggers.service.geomoby')
    .replace('live', 'triggers')
    .replace('3000', '8012'),
);
export const STREAMER_URL = atomFromEnv(
  'REACT_APP_STREAMER_URL',
  location.origin
    .replace('live.geomoby', 'streamer.service.geomoby')
    .replace('live-beta.geomoby', 'streamer.service.geomoby')
    .replace('live', 'streamer')
    .replace('3000', '8013'),
);
export const NOTIFIER_URL = atomFromEnv(
  'REACT_APP_NOTIFIER_URL',
  location.origin
    .replace('live.geomoby', 'notifier.service.geomoby')
    .replace('live-beta.geomoby', 'notifier.service.geomoby')
    .replace('live', 'notifier')
    .replace('3000', '8014'),
);
export const PYBAR_URL = atomFromEnv(
  'REACT_APP_PYBAR_URL',
  location.origin
    .replace('live.geomoby', 'underground.geomoby')
    .replace('live-beta.geomoby', 'undergroud-beta.geomoby')
    .replace('live', 'underground')
    .replace('3000', '8014'),
);
export const METADATA_URL = atomFromEnv(
  'REACT_APP_METADATA_URL',
  location.origin
    .replace('live.geomoby', 'metadata.service.geomoby')
    .replace('live-beta.geomoby', 'metadata.service.geomoby')
    .replace('live', 'metadata')
    .replace('3000', '8015'),
);
