import { FilterList, Search, Sort } from '@mui/icons-material';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { Dispatch, SetStateAction, useState } from 'react';
import { Header } from '../../Common/Sidebar';
import InputContainer from '../Global/InputContainer';
import { OrderType, OrderTypeValue, ToolOrderByTypeValue, ToolType } from '../Map/types';
import { OrderTypes, ToolOrderByTypes } from '../Map/values';

export type ToolTypeFilter = {
  tool?: string;
  order?: OrderType;
};

export const ToolTypeFilterDialog = ({
  setOpenToolTypeFilterDialog,
  toolTypes,
  setToolTypes,
  setRefreshToolTypes,
  toolTypeFilter,
  setToolTypeFilter,
}: {
  setOpenToolTypeFilterDialog: Dispatch<SetStateAction<boolean>>;
  toolTypes: ToolType[];
  setToolTypes: Dispatch<SetStateAction<ToolType[]>>;
  setRefreshToolTypes: Dispatch<SetStateAction<boolean>>;
  toolTypeFilter: ToolTypeFilter | undefined;
  setToolTypeFilter: Dispatch<SetStateAction<ToolTypeFilter | undefined>>;
}) => {
  return (
    <>
      {/*Filter*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '25px 0px 5px 25px',
        }}
      >
        <Header icon={<FilterList />}>Filters</Header>
      </div>

      {/*Search Label*/}
      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {
          <FormControl
            fullWidth
            style={{
              marginBottom: 20,
              alignSelf: 'center',
            }}
          >
            <InputContainer
              id="searchLabel"
              label="Label"
              key={'searchLabel'}
              name={'searchLabel'}
              value={toolTypeFilter?.tool ?? ''}
              onChange={(e: { target: { value: string } }) => {
                setToolTypeFilter({
                  ...toolTypeFilter,
                  tool: e.target.value,
                });
              }}
              placeholder="Tool1"
            />
          </FormControl>
        }
      </Grid>

      {/*Sort*/}
      <div
        style={{
          width: '100px',
          alignSelf: 'start',
          margin: '0px 0px 5px 10px',
        }}
      >
        <Header icon={<Sort />}>Sort</Header>
      </div>

      <Grid
        style={{
          height: '80%',
          width: 'calc(100% - 10vw)',
          alignSelf: 'center',
        }}
      >
        {/*Order*/}
        <FormControl
          fullWidth
          style={{
            marginBottom: 20,
            alignSelf: 'center',
          }}
        >
          <InputLabel id="order-option">Order</InputLabel>
          <Select
            fullWidth
            labelId="order-action"
            id="order-dropdown"
            value={toolTypeFilter?.order?.value ?? ''}
            label="Order"
            onChange={e => {
              const order = OrderTypes.find(l => l.value === (e.target.value as OrderTypeValue));
              setToolTypeFilter({
                ...toolTypeFilter,
                order,
              });
            }}
          >
            {OrderTypes.map(orderBy => (
              <MenuItem key={orderBy.id} value={orderBy.value}>
                <Tooltip title={orderBy.value}>
                  <Typography
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: 'calc(100% - 50px)',
                    }}
                  >
                    {orderBy.value}
                  </Typography>
                </Tooltip>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <Grid
        container
        direction="column"
        style={{
          alignSelf: 'end',
          margin: '25px',
          width: 'fit-content',
          display: 'grid',
          gap: '6%',
          gridTemplateColumns: '30% 30% 30%',
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => {
            setRefreshToolTypes(true);
            setToolTypeFilter(undefined);
            setOpenToolTypeFilterDialog(false);
          }}
        >
          Clear
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={() => setOpenToolTypeFilterDialog(false)}
        >
          Cancel
        </Button>
        <Button
          size="small"
          color="primary"
          variant="outlined"
          onClick={() => {
            let filterToolTypes = toolTypes.filter(toolType => {
              return toolTypeFilter?.tool ? toolType.tool.includes(toolTypeFilter.tool) : toolType;
            });
            filterToolTypes =
              toolTypeFilter?.order?.id === 'ASC'
                ? filterToolTypes.sort((a, b) => a.tool.localeCompare(b.tool))
                : filterToolTypes.sort((a, b) => b.tool.localeCompare(a.tool));
            setToolTypes(filterToolTypes);
            setOpenToolTypeFilterDialog(false);
          }}
        >
          Search
        </Button>
      </Grid>
    </>
  );
};
